import { baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventGTM,
    EventLabelsGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';

/** Показ экрана подключения биометрии
 *  Событие срабатывает при показе экран с выбором способа авторизации
 *  scrn */

export const showScreenPermissionUseBioGTM = () => {
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.CONNECT_BIOMETRIYA,
        screenName: ScreenNamesGTM.BIOMETRIYA,
    });
};

/** Показ экрана с выбором способа авторизации
 *  Событие срабатывает каждый раз при просмотре страницы
 *  scrn */
export const showScreenAuthFormGTM = () => {
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.LOGIN_METHOD,
        screenName: ScreenNamesGTM.AUTH,
    });
};

/** Показ toast при подключении биометрии
 *  Событие вызывается при показе toast с успехом подключения
 *  auth_form-confirmed-biometriya_podkluchena */
export const showToastConnectEntranceByBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        eventCategory: EventCategoryGTM.AUTH_FORM,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventAction: EventActionGTM.ELEMENT_STATUS,
        eventLabel: EventLabelsGTM.BIOMETRY_RECEIVED,
        userAuth: UserAuthGTM.AUTHENTICATED,
        screenName: ScreenNamesGTM.BIOMETRIYA,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
};
