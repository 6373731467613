import React, { FC } from 'react';
import styled from 'styled-components';
import { palette } from 'lib/theme';
import { Text } from 'components/common/Text';
import { isWebview } from 'utils/isWebview';
import { isNewPolicyLink } from 'utils/appVersions';
import { accessConditionsClickGtm, privacyPolicyClickGtm } from 'utils/GTM';

const newPolicy = 'https://static.ssl.mts.ru/mts_rf/images/usloviya-edinogo-dostupa-k-servisam-MTS.html';
const oldPolicy = 'https://static.mts.ru/uploadmsk/contents/1655/soglashenie_easy_pay.pdf';

interface Params {
    login: string;
}

export const PolicyText: FC<Params> = ({ login }) => (
    <PolicyTextStyled>
        <span>Нажимая «Далее», вы принимаете </span>
        <Link
            onClick={() => accessConditionsClickGtm({ login })}
            href={isNewPolicyLink() ? newPolicy : oldPolicy}
            target="_blank"
            rel="nofollow norefferrer noopener"
        >
            условия доступа
        </Link>
        {!isWebview() && (
            <>
                <span> и </span>
                <Link
                    onClick={() => privacyPolicyClickGtm({ login })}
                    href="https://www.mtsbank.ru/upload/iblock/ba8/politika-konfidencialnosti-servisa-mts-dengi.pdf"
                    target="_blank"
                    rel="nofollow norefferrer noopener"
                >
                    политику конфиденциальности
                </Link>
            </>
        )}
    </PolicyTextStyled>
);

const PolicyTextStyled = styled(Text)`
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${palette.gray};
`;

const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: ${palette.blue};
    font-size: 0.875rem;
`;
