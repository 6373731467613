import React from 'react';
import styled from 'styled-components';

export const PhonePrefix = () => (
    <PhonePrefixStyled>
        <span>+7</span>
    </PhonePrefixStyled>
);

const PhonePrefixStyled = styled.div`
    position: absolute;
    height: 3.25rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    left: 0;
    top: 0;

    span {
        line-height: 1.5rem;
    }
`;
