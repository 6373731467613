import { baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    ClickAnotherNumberPopUpParams,
    EventActionGTM,
    EventCategoryGTM,
    EventContentGTM,
    EventGTM,
    EventLabelsGTM,
    PageTypeGTM,
    ScreenNamesGTM,
    ShowPopRestrictedParams,
    UserAuthGTM,
} from 'utils/GTM/types';

/** Показ экрана "В вашем аккаунте МТС Банка указан другой номер телефона" или "Ваш номер используется в чужом аккаунте"
 * scrn */
export const showScreenRestrictedGTM = ({ isWrongNumber }: ShowPopRestrictedParams) => {
    return sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: isWrongNumber ? EventLabelsGTM.WRONG_TIED_NUMBER : EventLabelsGTM.WRONG_ACCOUNT,
        screenName: ScreenNamesGTM.WARNING,
    });
};

/** Клики по кнопкам на экране В вашем аккаунте указан другой номер телефона или "ваш номмер используется в чужом аккаунте"
 * auth_form-button_click-pokazat_otdeleniya
 * auth_form-click-otdeleniya_na_karte
 * auth_form-lick-pozvonit_v_bank-
 * auth_form-button_click-voiti_v_ogranichennom_rezhime-drugoi_privyazannyi_nomer
 * */
export const clickRestrictedGTM = ({ login, eventLabel, isWrongNumber, isWrongAccount }: ClickAnotherNumberPopUpParams) => {
    let eventContent: EventContentGTM | null = null;

    if (isWrongNumber) {
        eventContent = EventContentGTM.ANOTHER_LINKED_NUMBER;
    } else if (isWrongAccount) {
        eventContent = EventContentGTM.SOMEONE_ELSE_PHONE;
    }
    sendGmtEvent({
        eventLabel,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.BUTTON_CLICK,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.WARNING,
        eventContent,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
};
