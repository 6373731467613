import React, { FC } from 'react';
import { ToastType } from 'types/webauthn';
import styled from 'styled-components';
import { breakpoints } from 'lib/theme';
import { Button } from '@mtsbank/ui-kit/';

interface ToastButtonProps {
    type: ToastType;
    leftButtonTitle?: string;
    onContinueCLick?: () => void;
    onRepeatClick?: () => void;
    rightButtonTitle?: string;
}
export const ToastButtons: FC<ToastButtonProps> = ({ type, leftButtonTitle, rightButtonTitle, onContinueCLick, onRepeatClick }) => {
    return (
        <ToastButtonsWrapper>
            <Button size="sm" sizemob="sm" variant="secondary" onClick={onContinueCLick}>
                {leftButtonTitle || 'В\u00A0личный кабинет'}
            </Button>
            {type === ToastType.ERROR && onRepeatClick && (
                <Button size="sm" sizemob="sm" variant="secondary" onClick={onRepeatClick}>
                    {rightButtonTitle || 'Повторить'}
                </Button>
            )}
        </ToastButtonsWrapper>
    );
};

const ToastButtonsWrapper = styled.div`
    display: flex;
    min-width: 100%;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 16px;
    margin-left: -32px;
    column-gap: 12px;

    > :nth-child(n) {
        width: auto;
    }

    @media (min-width: ${breakpoints.mobileM}) {
        flex-direction: row;
        margin-left: 0;

        > :nth-child(n) {
            max-width: max-content;
        }
    }
`;
