const setUserGeoposition = (position: GeolocationPosition) => {
    const { longitude, latitude } = position.coords;
    return { longitude, latitude };
};

const setMoscowGeoposition = () => {
    return { longitude: '37.627931', latitude: '55.756754' };
};

export const getGeoposition = () => {
    const defaultGeoposition = setMoscowGeoposition();
    if (!navigator.geolocation) {
        return defaultGeoposition;
    }
    const result = navigator.geolocation.getCurrentPosition(setUserGeoposition);

    return result === undefined ? defaultGeoposition : result;
};
