export const modalsData = {
    loginLock: {
        heading: 'Попробуйте через\u00A05\u00A0минут',
        message:
            'Временно\u00A0заблокировали ваш\u00A0аккаунт.\nВероятно,\u00A0вы\u00A0превысили лимит\u00A0SMS\u2011подтверждений или\u00A0несколько\u00A0раз\u00A0ввели неверные данные. Убедимся,\u00A0что\u00A0аккаунт в\u00A0безопасности,\u00A0и\u00A0разблокируем.',
        primaryButton: 'Позвонить в банк',
        secondaryButton: 'Войти с другим номером',
    },
    // костыль для аналитики
    loginLock2: {
        heading: 'Попробуйте через\u00A05\u00A0минут',
        message:
            'Временно заблокировали ваш\u00A0аккаунт.\nВероятно,\u00A0вы\u00A0превысили лимит\u00A0SMS\u2011подтверждений или\u00A0несколько\u00A0раз\u00A0ввели неверные данные. Убедимся,\u00A0что\u00A0аккаунт в\u00A0безопасности,\u00A0и\u00A0разблокируем.',
        primaryButton: 'Позвонить в банк',
        secondaryButton: 'Войти с другим номером',
    },
    smsLimit: {
        heading: 'Попробуйте через\u00A05\u00A0минут',
        message: `Вы несколько раз ввели неверный\u00A0SMS\u2011код.\nЧтобы\u00A0обезопасить ваш\u00A0аккаунт,\nзаблокировали\u00A0его\u00A0на\u00A05\u00A0минут`,
        primaryButton: 'Войти с другим номером',
    },
    smsLock: {
        heading: 'Нужна проверка',
        message:
            'Заметили подозрительную активность с\u00A0этого устройства. Нужно убедиться, что ваши данные в\u00A0безопасности: поможем по\u00A0телефону 8\u00A0800\u00A0250-0-520 или в ближайшем\u00A0отделении',
        primaryButton: 'Позвонить в банк',
        secondaryButton: 'Отделения на карте',
    },
    smsNotFound: {
        heading: 'Вы клиент МТС банка?',
        message: 'Уточняем, потому\u00A0что не\u00A0нашли такого\u00A0номера\u00A0в\u00A0банке',
        primaryButton: 'Да, я клиент банка',
        secondaryButton: 'Нет',
    },
    identityLock: {
        heading: 'Попробуйте через\u00A05\u00A0минут',
        message:
            'Вы\u00A03\u00A0раза\u00A0ввели\u00A0неверные\u00A0данные. Чтобы\u00A0обезопасить ваш\u00A0аккаунт, заблокировали\u00A0его\u00A0на\u00A05\u00A0минут',
        primaryButton: 'Позвонить в банк',
        secondaryButton: 'Войти с другим номером',
    },
    wrongPerson: {
        heading: 'Вы клиент МТС банка?',
        message:
            'Мы\u00A0хотим\u00A0уточнить,\u00A0потому\u00A0что нашли\u00A0такой\u00A0номер в\u00A0аккаунте другого\u00A0клиента\u00A0банка',
        primaryButton: 'Да, я клиент банка',
        secondaryButton: 'Нет',
    },
    networkError: {
        heading: 'Ошибка соединения',
        primaryButton: 'Попробовать ещё раз',
    },
} as const;
