export const getCursorPosition = (prevValue: string, newValue: string, cursor: number) => {
    const diff = prevValue.length - newValue.length;
    const index = diff > 0 ? cursor : cursor - 1;
    const part1 = prevValue.slice(0, index);
    const part2 = prevValue.slice(index);
    // set cursor after special symbol and next number on paste
    if (part2.startsWith(' ') || part2.startsWith('-')) {
        return part1.length + 2;
    }

    if (Math.abs(diff) > 1) {
        // set cursor to correct place on delete
        if (diff > 0) {
            return index;
        }
        // set cursor to correct place on paste in the middle of input
        return part2 !== '' ? part1.length + 1 : newValue.length;
    }
    return cursor;
};

export const getNicknameCursorPosition = (value: string, newValue: string, cursor: number) => {
    if (value.length > newValue.length) {
        const diff = value.length - newValue.length;
        return cursor - diff;
    }
    return cursor;
};
