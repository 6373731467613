export const restrictedParams = {
    wrongNumber: {
        heading: 'В вашем аккаунте МТС\u00A0Банка указан другой номер телефона',
        firstText: 'Обновите номер: позвоните в\u00A0банк по телефону 8\u00A0800\u00A0250-0-520 или обратитесь в\u00A0ближайшее отделение.',
        lastText: 'Вы можете пользоваться сервисом в\u00A0ограниченном режиме.',
        primaryButton: 'Позвонить в банк',
        linkButton: 'Показать отделения на карте',
        secondaryButton: 'Войти в ограниченном режиме',
    },
    wrongAccount: {
        heading: 'Ваш номер используется в\u00A0другом аккаунте МТС\u00A0Банка',
        firstText:
            'Чтобы\u00A0мы обновили данные, позвоните в\u00A0банк по телефону 8\u00A0800\u00A0250-0-520 или обратитесь в\u00A0ближайшее\u00A0отделение.',
        primaryButton: 'Позвонить в банк',
        linkButton: 'Показать отделения на карте',
    },
    wrongData: {
        heading: 'Ваш номер используется в\u00A0другом аккаунте МТС\u00A0Банка',
        firstText:
            'Чтобы\u00A0мы обновили данные, позвоните в\u00A0банк по телефону 8\u00A0800\u00A0250-0-520 или обратитесь в\u00A0ближайшее\u00A0отделение.',
        lastText: 'Пока вы можете пользоваться сервисом в\u00A0ограниченном режиме.',
        primaryButton: 'Позвонить в банк',
        linkButton: 'Показать отделения на карте',
        secondaryButton: 'Войти в ограниченном режиме',
    },
} as const;

export type RestrictedType = keyof typeof restrictedParams;

export enum RestrictedTypes {
    wrongNumber = 'wrongNumber',
    wrongAccount = 'wrongAccount',
    wrongData = 'wrongData',
}

export const isRestrictedType = (data: any): data is RestrictedType => data in restrictedParams;
