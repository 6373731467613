/* eslint-disable no-bitwise */
/**
 * Функция генерации uuid
 * @returns {string} uuid
 */
export function uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (templateSymbol) => {
        const randomNumber = (Math.random() * 16) | 0;

        const id = templateSymbol === 'x' ? randomNumber : (randomNumber & 0x3) | 0x8;

        return id.toString(16);
    });
}
