import styled from 'styled-components';
import { palette } from 'lib/theme';

export const Input = styled.input<{ isError?: boolean }>`
    width: 100%;
    max-width: 100%;
    height: 3.25rem;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    border: 2px solid ${({ isError }) => (isError ? palette.orange : palette.extraLightGray)};
    font-feature-settings: 'tnum' on, 'lnum' on;
    background-color: ${palette.white};
    -webkit-box-shadow: inset 0 0 0 50px ${palette.white} !important;
    outline: none;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-ms-clear {
        display: none;
    }

    &::placeholder {
        color: ${palette.midGray};
    }

    &:active,
    &:focus {
        border-color: ${({ isError }) => (isError ? palette.orange : palette.blue)};
    }
`;
