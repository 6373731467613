import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { doSuccess } from 'utils/doSuccess';
import { formatIdentityInput, Identity as IdentityType } from 'utils/formatIdentityInput';
import styled from 'styled-components';
import { Field } from 'components/fields/Field';
import { Content } from 'components/common/Content';
import { Form } from 'components/common/Form';
import { LinkButton } from 'components/common/LinkButton';
import { BlockAttemptsTime, StateContext } from 'state';
import { getLeftAttempts } from 'utils/getLeftAttemptsMessage';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { modalsData } from 'lib/modalsData';
import { getIdentityRequestData } from 'utils/getIdentityRequestData';
import { api } from 'utils/api';
import { apiUrls } from 'lib/apiUrls';
import { getCursorPosition } from 'utils/getCursorPosition';
import { stepParams } from 'lib/stepParams';
import { getNumericValue } from 'utils/getNumericValue';
import { ModalProps } from 'components/common/Modal';
import { sendEventToApp } from 'utils/sendEventToApp';
import {
    identityFieldOnBlurGTM,
    showScreenConfirmationPassportGTM,
    showScreenConfirmationToCardGTM,
    furtherIdentityGTM,
    successfulCardConfirmedGTM,
    successfulPassportConfirmedGTM,
    unsuccessfulCardConfirmedGTM,
    unsuccessfulPassportConfirmedGTM,
    clickBackGTM,
    ScreenNamesGTM,
    AuthTypeGTM,
} from 'utils/GTM';

type FormValues = Record<string, string>;

interface Props {
    type: IdentityType;
}

export const Identity = ({ type }: Props) => {
    const {
        identity: {
            heading,
            buttonText,
            [type]: { subheading, errorSubheading, errorMessage, minLength, maxLength },
        },
    } = stepParams;
    const {
        identityAttempts,
        setIdentityAttempts,
        setDisplayStep,
        setLoading,
        setModalProps,
        getErrorModal,
        closeModal,
        toggleModal,
        updateClient,
        login,
        setLogin,
        getIsBlockedTime,
        blockAttempts,
        setBlockAttempts,
    } = useContext(StateContext);

    const fieldId = `identity-${type}` as 'identity-card' | 'identity-id';
    const identifyIsRedirecting = window.sessionStorage.getItem('identifyIsRedirecting') === 'true';
    const [isRedirecting, setIsRedirecting] = useState(identifyIsRedirecting);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isError, setIsError] = useState(false);
    const [displayAttempts, setDisplayAttempts] = useState(false);
    const [resubmitting, setResubmitting] = useState(false);
    const [lastInput, setLastInput] = useState('');

    const inputError = [errorMessage, getLeftAttempts(identityAttempts)].join('\n');
    const inputWarn = getLeftAttempts(identityAttempts);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isSubmitting },
    } = useForm<FormValues>();

    const modalProps = useMemo<ModalProps>(
        () => ({
            ...modalsData.identityLock,
            onPrimaryClick: 'callToBank',
            onSecondaryClick: (e) => {
                sendEventToApp();
                updateClient()(e);
            },
        }),
        [updateClient]
    );

    const displayModal = useCallback(() => {
        setDisplayStep('login');
        setModalProps(modalProps);
        toggleModal();
    }, [toggleModal, setModalProps, setDisplayStep, modalProps]);

    const setAttemptError = useCallback(() => {
        sessionStorage.setItem('error', 'true');
        setIsError(true);
    }, []);

    useEffect(() => {
        if (sessionStorage.error) {
            setIsError(true);
        } else if (identityAttempts < 3) {
            setDisplayAttempts(true);
        }

        return () => {
            sessionStorage.removeItem('error');
            setDisplayAttempts(false);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(isSubmitting || resubmitting || isRedirecting);
        return () => {
            setLoading(false);
        };
    }, [isSubmitting, setLoading, resubmitting, isRedirecting]);

    useEffect(() => {
        let isBlockedTime = false;

        if (blockAttempts.login) {
            const isCurrentLogin = blockAttempts?.login === login;
            isBlockedTime = getIsBlockedTime(blockAttempts.time, isCurrentLogin);
        }

        if (identityAttempts < 1 || isBlockedTime) {
            displayModal();
            setLogin('');
        }
    }, [identityAttempts, displayModal, blockAttempts, login, getIsBlockedTime, setLogin]);

    const handleIdentityError = useCallback(
        (attempts: number) => {
            setIdentityAttempts(attempts - 1);
            setAttemptError();
            if (attempts === 1) {
                const currentBlockAttempts: BlockAttemptsTime = { login, time: Date.now().toString() };
                setBlockAttempts(currentBlockAttempts);
                sessionStorage.setItem('blockAttempts', JSON.stringify(currentBlockAttempts));
            }

            if (attempts < 1) {
                displayModal();
            }
        },
        [displayModal, login, setAttemptError, setBlockAttempts, setIdentityAttempts]
    );

    const onSubmit = useCallback(
        async (data: FormValues, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            const isTypeCard = type === IdentityType.card;
            furtherIdentityGTM({ login, isPassport: !isTypeCard });
            const identityValue = isTypeCard ? getNumericValue(data[fieldId]) : data[fieldId];
            const requestData = getIdentityRequestData(type, identityValue);

            try {
                const result = await api.postJSON(apiUrls.additional, requestData);

                if (result.redirect_url) {
                    setIsRedirecting(true);
                    sessionStorage.setItem('identifyIsRedirecting', 'true');

                    window.gib.setAuthStatus(true);
                    if (isTypeCard) {
                        successfulCardConfirmedGTM({ login });
                    } else {
                        successfulPassportConfirmedGTM({ login });
                    }
                    doSuccess({ login, redirect_url: result.redirect_url, gtmProps: { isTypeCard, authTypeGTM: AuthTypeGTM.ADDITIONAL } });
                    return;
                }

                if (result.next_step === 'sms_confirm') {
                    setDisplayStep('otp');
                }
            } catch (error) {
                setDisplayAttempts(false);
                setResubmitting(false);
                if (isTypeCard) {
                    unsuccessfulCardConfirmedGTM({ login });
                } else {
                    unsuccessfulPassportConfirmedGTM({ login });
                }
                if (error.status === 400 && error.messageCode === 'verify_additional_data') {
                    handleIdentityError(identityAttempts);
                } else if (error.status === 400 && error.messageCode === 'not_login') {
                    setDisplayStep('login');
                } else {
                    setResubmitting(false);
                    getErrorModal(
                        () => {
                            closeModal();
                            setResubmitting(true);
                            setTimeout(() => onSubmit(data, e), 300);
                        },
                        error,
                        isTypeCard ? ScreenNamesGTM.CONFIRMATION_CARD : ScreenNamesGTM.CONFIRMATION_PASSPORT
                    );
                }
            }
        },
        [closeModal, fieldId, getErrorModal, handleIdentityError, identityAttempts, login, setDisplayStep, type]
    );

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            const cursorPosition = e.target.selectionStart;
            const idValue = formatIdentityInput(value, type);
            setValue(fieldId, idValue);
            const newCursorPosition = getCursorPosition(lastInput, idValue, cursorPosition || 0);
            e.target.selectionStart = newCursorPosition;
            e.target.selectionEnd = newCursorPosition;
            setLastInput(idValue);

            if (idValue.length >= minLength && identityAttempts >= 1) {
                setIsButtonActive(true);
            } else {
                setIsButtonActive(false);
            }
        },
        [fieldId, identityAttempts, lastInput, minLength, setValue, type]
    );

    const onClearClick = useCallback(() => {
        setValue(fieldId, '');
        setIsButtonActive(false);
    }, [fieldId, setValue]);

    const onClickGoBack = useCallback(() => {
        clickBackGTM({ login, isPassport: type === IdentityType.id, isCards: type === IdentityType.card });
        setDisplayStep('confirmation');
    }, [login, setDisplayStep, type]);

    const subheadingWithAdditional = [subheading, errorSubheading].join('\n');

    useEffect(() => {
        if (type === IdentityType.id) {
            showScreenConfirmationPassportGTM();
        }
        if (type === IdentityType.card) {
            showScreenConfirmationToCardGTM();
        }
    }, [type]);

    const handleBlurGTM = useCallback(() => {
        if (isButtonActive) {
            identityFieldOnBlurGTM({ login, isPassport: fieldId === 'identity-id' });
        }
    }, [fieldId, isButtonActive, login]);

    return (
        <Content heading={heading} subheading={isError ? subheadingWithAdditional : subheading}>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                buttonText={buttonText}
                error={inputError}
                isError={isError}
                isSubmitting={isSubmitting}
                isButtonActive={isButtonActive}
            >
                <Field
                    id={fieldId}
                    type="text"
                    inputMode="text"
                    autoComplete="on"
                    minLength={minLength}
                    maxLength={maxLength}
                    register={register}
                    isError={isError}
                    isFocused={!isSubmitting}
                    onChange={onChange}
                    onClick={onClearClick}
                    disabled={isSubmitting}
                    onBlurGTM={handleBlurGTM}
                />
                {displayAttempts && <AttemptInfo data-testid="attempts">{inputWarn}</AttemptInfo>}
            </Form>
            <LinkButton onClick={onClickGoBack} disabled={isSubmitting}>
                Назад
            </LinkButton>
        </Content>
    );
};

const AttemptInfo = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0.25rem;
`;
