import React from 'react';
import styled from 'styled-components';
import { palette } from './theme';

const Link = styled.a`
    text-decoration: none;
    color: ${palette.blue};
`;

const List = styled.ul`
    padding-left: 0;
    margin: 1em 0;
    line-height: 1.5;
    list-style: none;

    li {
        position: relative;
    }

    li:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${palette.deepBlue};
        position: relative;
        vertical-align: super;
        top: 3px;
        margin-right: 3px;
    }
`;

const TableWrapper = styled.div`
    max-width: 100%;
    position: relative;
    overflow-x: auto;
`;

const Table = styled.table`
    border-spacing: 0;
`;

const Cell = styled.td`
    vertical-align: top;
    border-bottom: 1px solid ${palette.lightMidGray};
    padding: 0 6px;
`;

export const offerText = (
    <>
        <div>
            <p>
                <strong>1. </strong>
                <strong>Термины </strong>
            </p>
            <p>
                1.1. Сервис «Кошелек МТС Деньги» (равнозначно «Сервис») – услуги, оказываемые Пользователям и позволяющие Пользователям
                направлять Распоряжения на совершение Операций и Платежей. Сервис предоставляется МТС при участии в оказании определенного
                функционала Сервиса лицами (далее «Партнеры»), указанными в{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_mts_pay.pdf" target="_blank" rel="nofollow">
                    Условиях предоставления сервиса «Кошелек МТС Деньги» при оплате с помощью денежных средств на лицевом счете
                </Link>{' '}
                (для Абонентов МТС – физических лиц),{' '}
                <Link
                    href="http://static.mts.ru/uploadmsk/contents/1655/usloviya_predostavl_servisa_s_bk.pdf"
                    target="_blank"
                    rel="nofollow"
                >
                    Условиях предоставления сервиса «Кошелек МТС Деньги» с использованием денежных средств на банковской карте
                </Link>
                ,{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/usl_use_eletron_money.pdf" target="_blank" rel="nofollow">
                    Условиях предоставления сервиса «Кошелек МТС Деньги» с использованием электронных денежных средств ПАО «МТС Банк»
                </Link>{' '}
                (далее в целом «Условия). Возможности Сервиса могут быть реализованы Пользователем через следующие функционалы:
                специализированный раздел Сайта, Мобильное Приложение «МТС Деньги» и «Мой МТС», ТВ-приставка, USSD-портал, Перевод по SMS
                <sup>1</sup>, Перевод по SMS на карту, Выдача денежных средств с телефона МТС; Технические средства Поставщиков услуг,
                Платежную форму, SMS-портал.
            </p>
            <p>
                1.2. Оператор (МТС) – ПАО «МТС» и(или) иные дочерние компании ПАО «МТС», оказывающие услуги связи и/или иные услуги под
                товарным знаком «МТС», осуществляющее свою деятельность в соответствии с законодательством РФ и условиями выданных лицензий,
                предоставляющее Пользователям при участии в оказании определенных функционалов Сервиса Партнеров возможность использования
                сервиса «Кошелек МТС Деньги».
            </p>
            <p>
                1.3. Абонент – физическое или юридическое лицо, заключившее с любым действующим на основании лицензии на оказание услуг
                подвижной радиотелефонной связи российским оператором связи договор об оказании услуг подвижной радиотелефонной связи;
                Абонент МТС – физическое или юридическое лицо, заключившее с Оператором Договор об оказании услуг подвижной радиотелефонной
                связи МТС, Договор об оказании услуг связи (для целей телерадиовещания) по фиксированным сетям ПАО «МТС» -применительно к
                ТВ-приставке (далее любой из договоров «абонентский договор»).
            </p>
            <p>
                1.4. Пользователь – физическое лицо, являющееся Абонентом или пользователем услуг подвижной радиотелефонной связи (когда
                физическое лицо выступает от имени юридического лица – Абонента), достигшее возраста совершеннолетия, с учетом ограничений
                по доступности Сервиса, указанных в настоящем пункте, прошедшее регистрацию в Сервисе «Кошелек МТС Деньги».
            </p>
            <p>
                При использовании сервиса «Кошелек МТС Деньги» с помощью USSD-портала, Мобильного приложения «Мой МТС», ТВ-приставки,
                Перевода по SMS, Перевода по SMS на карту, Технических средств Поставщиков услуг – Пользователем может являться
                исключительно Абонент МТС. При использовании сервиса «Кошелек МТС Деньги» при оплате с помощью денежных средств на лицевом
                счете Абонента МТС, при использовании функционала Сервиса «Выдача денежных средств с телефона МТС» Пользователем может
                являться исключительно Абонент МТС-физическое лицо. Оказание Сервиса с помощью Мобильного приложения и Сайта доступно
                абонентам тех операторов связи, у которых имеется возможность получения на их мобильный номер сети подвижной связи своего
                оператора связи сообщений с сервисных номеров MTSDengi и 1212. При регистрации в Сервисе физического лица, выступающего от
                имени Абонента - юридического лица, физическое лицо подтверждает, что наделено соответствующими полномочиями от юридического
                лица для использования Сервиса, а при использовании Сервиса в личных целях, такое физическое лицо заверяет МТС, что все
                согласия от юридического лица для использования абонентского номера в качестве идентификатора и использование сведений о
                таком абонентском номере для целей Сервиса, получены.
            </p>
            <p>
                1.5. Сервисный номер – номер, с которого отправляются информационные сообщения от МТС в рамках предоставления Сервиса
                «Кошелек МТС Деньги».
            </p>
            <p>
                1.6. Персональный кабинет Пользователя – специализированный раздел Сайта для доступа к данным банковских карт, электронных
                средств платежа для сервиса «Кошелек МТС Деньги» Пользователя, истории его Платежей и списку шаблонов Сервиса, защищенных
                специальными средствами защиты и содержащие персональные данные Пользователя (и данные Абонента), в том числе результаты
                успешно совершенных Платежей.
            </p>
            <p>
                1.7. Сайт сервиса «Кошелек МТС Деньги»/ Сайт ({' '}
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>
                ) – интерфейс Сервиса, специализированный раздел официального сайта МТС, защищенный специальными средствами защиты для
                доступа в Персональный кабинет Пользователя.
            </p>
            <p>
                1.8. Мобильное приложение «МТС Деньги», «Мой МТС» («Мобильное приложение») – интерфейс Сервиса, программное обеспечение,
                поддерживающее динамическое меню, загружаемое Пользователем на Абонентское оборудование Пользователя при условии, что такое
                Абонентское оборудование поддерживает функционал Мобильного приложения (подробная информация о том, какое Абонентское
                оборудование поддерживает функционал Мобильного приложения указана на сайте МТС{' '}
                <Link href="http://www.mts.ru/" target="_blank" rel="nofollow">
                    www.mts.ru
                </Link>{' '}
                в разделе «МТС Деньги»), с целью предоставить Пользователю доступ к Сервису.
            </p>
            <p>
                1.9. USSD<sup>2</sup>-портал – интерфейс Сервиса, обеспечивает среду для выполнения логики USSD сервисов, позволяющий
                организовать интерактивное взаимодействие между Абонентом МТС и Сервисом в режиме передачи коротких сообщений; пользование
                USSD и передача команд осуществляется с абонентского оборудования услуг подвижной радиотелефонной связи. В настоящий момент
                доступ к USSD-порталу предоставляется по номерам *115#, *111#, *700#, *611#. С помощью USSD- портала возможно оплачивать
                товары, работы, услуги Поставщиков услуг и совершать USSD- перевод.
            </p>
            <p>
                1.10. Перевод с помощью USSD (USSD-перевод) – интерфейс Сервиса USSD-Портала, позволяющий Абоненту МТС отправлять Платеж
                абоненту Оператора или другого оператора услуг подвижной радиотелефонной связи, посредством передачи коротких
                сообщений-команд. С помощью USSD-перевода так же Абонент МТС имеет возможность перевести денежные средства на счет «Мой
                Кошелек» и банковскую карту, эмитированную банком, имеющим лицензию на территории Российской Федерации. USSD-перевод
                возможен с лицевого счета либо со счета «Мой Кошелек».
            </p>
            <p>
                1.11. ТВ-приставка – пользовательское (оконечное) оборудование (ТВ-декодер, ТВ-модуль CAM и т.п.), предназначенное для
                приема, обработки и воспроизведения сигналов телеканалов (радиоканалов), посредством которого Абонент МТС получает
                техническую возможность потребления услуг связи для целей телерадиовещания в соответствии с{' '}
                <Link
                    href="https://moskva.mts.ru/personal/podderzhka/domashniy-internet-i-tv/poleznaya-informaciya/usloviya-okazaniya-uslug-svyazi"
                    target="_blank"
                    rel="nofollow"
                >
                    Условиями оказания услуг связи для целей телерадиовещания
                </Link>
                ,{' '}
                <Link
                    href="https://moskva.mts.ru/personal/podderzhka/domashniy-internet-i-tv/poleznaya-informaciya/usloviya-okazaniya-uslug-svyazi"
                    target="_blank"
                    rel="nofollow"
                >
                    Порядком предоставления абонентского оборудования и смарт-карт ПАО «МТС»
                </Link>
                .
            </p>
            <p>
                1.12. Перевод по SMS (техническое SMS-сообщение на «федеральный номер» (абонентский номер в коде DEF, выделенный для
                получения услуг подвижной радиотелефонной связи) оператора связи) – интерфейс Сервиса, позволяющая Абоненту МТС отправить
                абоненту Оператора или другого оператора услуг подвижной радиотелефонной связи Платеж посредством сообщения с текстом:
                #перевод_числовое значение суммы перевода (с одним пробелом между командой и суммой перевода). Пример: #перевод 100. Под SMS
                следует понимать короткое текстовое сообщение, направляемое с оборудования, поддерживающего такой функционал.
            </p>
            <p>
                1.13. Перевод по SMS на карту – интерфейс Сервиса, позволяющая Абоненту МТС отправить запрос на перевод с лицевого счёта
                Абонента МТС на банковскую карту любого банка посредством SMS-сообщения на короткий номер 6111 с текстом: card номер_карты
                сумма_перевода. Цифры номера карты следует прописывать слитно, не разделяя на тетрады. Между командой, номером карты и
                суммой перевода (в рублях) по одному пробелу. Пример: card 1234123412341234 500
            </p>
            <p>
                1.14. Платежная форма – интерфейс Сервиса, часть Сайта, развернутый на стороне МТС ({' '}
                <Link href="https://widget.mts.ru/" target="_blank" rel="nofollow">
                    https://widget.mts.ru
                </Link>
                ) и доступный с ресурсов Поставщиков услуг, позволяющий Пользователю совершить Платеж.
            </p>
            <p>
                1.15. Выдача денежных средств с телефона МТС – функционал Сервиса, позволяющий Пользователю-физическому лицу, достигшему
                совершеннолетия, получить наличные денежные средства с лицевого счёта мобильного телефона МТС в определенных
                салонах-магазинах МТС путем формирования Пользователем (Абонентом МТС-физическим лицом) распоряжения на увеличение остатка
                электронных денежных средств на выпускаемой для этих целей Оператором электронных денежных средств Предоплаченной карты на
                сумму, указанную Пользователем, и осуществления Платежа (в данном случае под Платежом понимается непосредственно выдача
                наличных денежных средств при соответствии определенным условиям, указанным в настоящем Соглашении) на сумму, указанную в
                распоряжении Пользователя на увеличение остатка электронных денежных средств на выпускаемой для этих целей оператором
                электронных денежных средств Предоплаченной карте. Осуществление Платежа производится уполномоченным представителем
                Оператора электронных денежных средств, выпустившим Предоплаченную карту, работающих в салонах-магазинах МТС.
            </p>
            <p>
                1.16. SMS-портал – интерфейс Сервиса, позволяющий организовать интерактивное взаимодействие между Абонентом и Сервисом в
                режиме передачи/получения SMS – сообщений для подключения Автоплатежа; пользование SMS-порталом для передачи команд на
                подключение Автоплатежа в ответ на полученное от Сервиса предложение с номера 1212 осуществляется с абонентского
                оборудования услуг подвижной радиотелефонной связи. Входящие SMS с номера 1212 не тарифицируются. Отправка SMS на номер 1212
                бесплатна для Абонента при нахождении в домашней сети, при нахождении в роуминге – тарифицируется согласно роуминговому
                тарифу.
            </p>
            <p>
                1.17. Мой Кошелек (счет «Мой Кошелек») – электронное средство платежа (ЭСП), выпущенное ПАО «МТС-Банк» в рамках Сервиса
                «Кошелек МТС Деньги» и в соответствии с{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Общими условиями осуществления перевода электронных денежных средств и обслуживания электронных средств платежа ПАО
                    «МТС-Банк
                </Link>{' '}
                и{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Договором ПАО «МТС-Банк» о выпуске и обслуживании ЭСП для сервиса «Кошелек МТС Деньги»
                </Link>
                . На один Номер, являющийся идентификатором счета «Мой Кошелек», может быть выпущено только одно ЭСП «Мой Кошелек».
            </p>
            <p>
                1.18. Номер – абонентский номер из ресурса сети подвижной радиотелефонной связи, выделенный Пользователю для целей
                пользования услугами подвижной радиотелефонной связи.
            </p>
            <p>
                1.19. Личный кабинет МТС – интернет-сервис МТС (
                <Link href="https://lk.ssl.mts.ru/" target="_blank" rel="nofollow">
                    https://lk.ssl.mts.ru
                </Link>
                ), доступный аутентифицированным Абонентам МТС, позволяющий получать единый доступ к сервисам самообслуживания Оператора в
                соответствии с{' '}
                <Link href="https://static.ssl.mts.ru/mts_rf/images/oferta_dengi_221019.pdf" target="_blank" rel="nofollow">
                    Соглашением о пользовании web-страницей «Личный кабинет МТС» на сайте ПАО «МТС» и приложением «Мой МТС»
                </Link>
                .
            </p>
            <p>
                1.20. Пароль для входа в Мобильное приложение или на Сайт – буквенно-цифровой код, получаемый в порядке, указанном в п.
                1.20.1. и п.1.20.2. Соглашения.
            </p>
            <p>
                1.20.1. Пароль для входа в Мобильное приложение «Мой МТС»- буквенно-цифровой код для доступа в Личный кабинет МТС,
                получаемый Пользователем (Абонентом МТС) в соответствии с «{' '}
                <Link href="https://static.ssl.mts.ru/mts_rf/images/oferta_dengi_221019.pdf" target="_blank" rel="nofollow">
                    Соглашением о пользовании web-страницей «Личный кабинет МТС»
                </Link>
                ..
            </p>
            <p>
                1.20.2. Пароль для входа на Сайт (
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>
                ) и в Мобильное приложение «МТС Деньги» - буквенно-цифровой код (отп код), состоящий из 4-х букв/цифр, направляемом в SMS с
                номера ПАО «МТС-Банк» в соответствии с{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Общими условиями осуществления перевода электронных денежных средств и обслуживания электронных средств платежа ПАО
                    «МТС-Банк»
                </Link>{' '}
                после ввода Пользователем своего Абонентского номера в интерфейсе Сайта или Мобильного приложения «МТС Деньги» и запроса
                Пользователем на Сайте/ в Мобильном приложении «МТС Деньги» Пароля. Полученный Пароль для входа на Сайт/ в Мобильное
                приложение «МТС Деньги» действует 60 минут, через 30 секунд после направления Пароля можно будет запросить новый Пароль.
                Количество запросов Пароля ограничено тремя попытками. В целях обеспечения безопасности и предотвращения мошенничества со
                стороны третьих лиц у Пользователя могут быть запрошены дополнительные сведения, идентифицирующие Пользователя, для
                предоставления Пароля.
            </p>
            <p>
                1.21. Регистрация – первичный корректный ввод Пользователем Номера и Пароля для доступа к сервису «Кошелек МТС Деньги» на
                Сайте, в Мобильном приложении. После первичной Регистрации Пользователю открывается аккаунт (учетная запись) для доступа в
                Сервис.
            </p>
            <p>
                1.22. Авторизация – ввод зарегистрированным Пользователем Номера и пароля на Сайте или только пароля в Мобильном приложении
                для целей возможности пользования Сервисом. По желанию Пользователя Авторизация в Мобильном приложении может осуществляться
                с помощью заданного Пользователем короткого кода либо, если позволяет функционал оборудования, воспользоваться Touch ID
                (идентификация по отпечатку пальца), Face ID (идентификация по лицу»).
            </p>
            <p>
                1.23. Платеж – оплата Пользователем товаров, работ, услуг Поставщиков услуг либо иной осуществляемый в соответствии с
                законодательством и допускаемый Сервисом Платеж с использованием банковских карт Пользователя и/или электронных денежных
                средств, пополняемых в т.ч. с лицевого счета Абонента МТС (когда это допускается законом), осуществляемый на основании
                Распоряжения Абонента.
            </p>
            <p>1.24. Распоряжение – сформированное Пользователем указание об осуществлении Платежа.</p>
            <p>
                1.25. Абонентское оборудование – оконечное (пользовательское) оборудование для пользования услугами подвижной
                радиотелефонной связи и/или мобильного Интернет: мобильный телефон, смартфон, планшет, ПК и аналогичные устройства
                Пользователя. Ограничения: Для пользования Сервисом с помощью USSD, в качестве абонентского оборудования может
                использоваться мобильный телефон и смартфон; с помощью Мобильного приложения – мобильный телефон, смартфон и планшет.
            </p>
            <p>
                1.26. Идентификационный модуль (далее равнозначно «SIM-карта») – электронный носитель информации, с помощью которого
                осуществляется идентификация Абонента Оператором, доступ Абонентского оборудования к сети подвижной связи, а также защита от
                несанкционированного использования выделенного Номера.
            </p>
            <p>
                1.27. Автоматический платеж в пользу Поставщика услуг (равнозначно «Автоплатеж») – услуга, отражаемая в Персональном
                кабинете Пользователя и обеспечивающая возможность автоматического проведения Платежей с использованием банковских карт
                Пользователя и/или электронных денежных средств, пополняемых в т.ч. с лицевого счета Абонента МТС (когда это допускается
                законом) на основании заранее предоставленного Пользователем Распоряжения с параметрами Платежа (по порогу, по расписанию
                или по выставленному счету).
            </p>
            <p>
                1.28. Упрощенная идентификация Пользователя (далее «Упрощенная идентификация») – осуществляемая в случаях, установленных
                законом от 07.08.2001 г. № 115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и
                финансированию терроризма», совокупность мероприятий, осуществляемых МТС по поручению кредитной организации либо
                непосредственно кредитной организацией (иными лицами, действующими по поручению кредитной организации) по установлению в
                отношении такого Пользователя фамилии, имени, отчества (если иное не вытекает из закона или национального обычая), серии и
                номера документа, удостоверяющего личность и подтверждению достоверности этих сведений одним из следующих способов:
            </p>
            <p>- с использованием оригиналов документов и (или) надлежащим образом заверенных копий документов;</p>
            <p>
                - с использованием информации из информационных систем органов государственной власти, Пенсионного фонда Российской
                Федерации, Федерального фонда обязательного медицинского страхования и (или) государственной информационной системы,
                определенной Правительством Российской Федерации;
            </p>
            <p>
                - с использованием единой системы идентификации и аутентификации при использовании усиленной квалифицированной электронной
                подписи или простой электронной подписи при условии, что при выдаче ключа простой электронной подписи личность физического
                лица установлена при личном приеме.
            </p>
            <p>
                По поручению кредитной организации мероприятия по проведению упрощенной идентификации могут включать в себя проведение
                проверки действительности паспорта Абонента, являющегося гражданином Российской Федерации, на информационном сервисе
                &quot;Проверка действительности паспорта гражданина Российской Федерации, удостоверяющего личность гражданина Российской
                Федерации на территории Российской Федерации&quot; на официальном сайте уполномоченного федерального органа в сети
                &quot;Интернет&quot;, проведение проверки Абонентов на наличие совпадений сведений Абонентов, полученных при упрощенной
                идентификации, со сведениями из актуального Перечня организаций и физических лиц, в отношении которых имеются сведения об их
                причастности к экстремистской деятельности или терроризму на сайте Федеральной службы по финансовому мониторингу.
            </p>
            <p>
                Упрощенная идентификация для Абонентов МТС-физических лиц проводится Оператором по поручению кредитной организации (ПАО
                «МТС-Банк», адрес места нахождения 115432, г. Москва, пр-т Андропова, д.18 корп.1). Упрощенная идентификация для граждан РФ
                Пользователей Абонентов МТС-юридических лиц, а также граждан РФ Пользователей услугами связи иных операторов связи
                проводится в салонах –магазинах, работающих под товарным знаком МТС (полный перечень на сайте{' '}
                <Link href="http://www.shop.mts.ru" target="_blank" rel="nofollow">
                    http://www.shop.mts.ru
                </Link>
                ), для проведения упрощенной идентификации необходимо лично явиться в указанные салоны-магазины и предоставить
                уполномоченному сотруднику паспорт гражданина РФ.
            </p>
            <p>
                Пользователю, прошедшему упрощенную идентификацию, доступны денежные переводы, оплата в пользу поставщиков
                товаров/работ/услуг зарегистрированных за пределами РФ, а также повышенные лимиты на платежи и переводы, и другие
                возможности. Подробнее обо всех возможностях на сайте{' '}
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>
                .
            </p>
            <p>
                1.29. Идентификация Пользователя (далее «Идентификация») - осуществляемая в случаях, установленных законом от 07.08.2001 г.
                № 115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма»
                (далее ФЗ-115), совокупность мероприятий, осуществляемых МТС по поручению кредитной организации либо непосредственно
                кредитной организацией (иными лицами, действующими по поручению кредитной организации) по установлению в отношении такого
                Пользователя фамилии, имени, отчества (если иное не вытекает из закона или национального обычая), гражданства, серии и
                номера документа, удостоверяющего личность, данные миграционной карты, даты рождения, адреса регистрации по месту жительства
                или места пребывания, ИНН, иной информации, позволяющей идентифицировать Пользователя, а также информации, подтверждающей
                указанные сведения. В случаях, указанных в ФЗ-115, проводится также идентификация бенефициаров Пользователей.
            </p>
            <p>Идентификация включает в себя мероприятия по актуализации данных в сроки и способами, указанными в ФЗ-115.</p>
            <p>
                Для прохождения процедуры идентификации Пользователю, являющемуся одновременно клиентом ПАО «МТС-Банк» на основании
                заключенного договора в письменной форме (имеется платежная карта, открыт счет, выдан кредит и пр.), необходимо войти в
                Мобильное приложение «МТС Деньги» либо идентифицироваться на Сайте путем указания логина и пароля для доступа к
                Интернет-банкингу ПАО «МТС-Банк». Пользователям, прошедшим процедуру идентификации, доступны любые Операции и Платежи, если
                только ограничение на проведение Платежа не будет вызвано обстоятельствами, не связанными с идентификацией Пользователя.
            </p>
            <p>
                1.30. Правила сервиса «МТС Cashback» – правила программы лояльности ПАО «МТС» для физических лиц, в рамках которой ее
                участник может накапливать и расходовать Кэшбэк в соответствии с Правилами сервиса «МТС Cashback». Полный текст Правил
                сервиса «МТС Cashback» размещен на сайте{' '}
                <Link href="http://www.mts.ru/" target="_blank" rel="nofollow">
                    www.mts.ru
                </Link>
                .
            </p>
            <p>
                1.31. Кэшбэк или Cashback – скидка, предоставляемая в соответствии с Правилами сервиса «МТС Cashback». Кэшбэк не является
                денежными средствами, средствами платежа или каким-либо видом валюты или ценной бумаги. Кэшбэк нельзя обменять на денежные
                средства.
            </p>
            <p>
                1.32. Поставщики услуг – поставщики товаров, работ, услуг, в пользу которых Пользователь может совершать Платежи. Под
                Поставщиком услуг в тех случаях, когда оплата осуществляется в адрес указанных лиц (ПАО «МТС», ПАО МГТС, ООО «ЦТВ», АО
                «Сибинтертелеком») соответственно следует понимать указанных лиц (ПАО «МТС», ПАО МГТС, ООО «ЦТВ», АО «Сибинтертелеком»).
            </p>
            <p>
                <strong>2. </strong>
                <strong>Общее описание: </strong>
            </p>
            <p>
                2.1. По настоящему Соглашению Оператор предоставляет возможность Пользователю с помощью Сервиса совершать Платежи. Сервисом
                можно воспользоваться путем списания денежных средств с лицевого счета Абонента МТС (только для Абонентов МТС-физических
                лиц), путем списания денежных средств со счета «Мой Кошелек» или путем списания денежных средств с банковской карты
                Пользователя. В соответствии с ФЗ-115 некоторые Операции и Платежи возможны только в случаях прохождения Пользователем или
                Пользователем и Получателем Платежа процедуры упрощенной идентификации/идентификации. Пользователь, принимая условия
                настоящего Соглашения, понимает и соглашается с тем, что некоторые Операции и Платежи до прохождения Пользователем процедуры
                упрощенной идентификации либо идентификации (если это требуется в соответствии ФЗ-115) будут недоступны для Пользователя
                <sup>3</sup>.
            </p>
            <p>
                2.2. Для целей возможности пользования Сервисом: 1) с помощью Мобильного приложения – Пользователю (Абоненту услуг подвижной
                радиотелефонной связи) необходимо установить на свое Абонентское оборудование Мобильное приложение, иметь доступ к сети
                Интернет и авторизоваться; 2) с помощью USSD – необходимо использовать Абонентское оборудование, поддерживающее возможность
                передачи команд с помощью USSD (телефон, смартфон); 3) с помощью Сайта – необходимо иметь доступ к сети Интернет и
                авторизоваться; 4) с помощью Перевода по SMS с текстом следующего содержания: #перевод (числовое значение суммы перевода),
                например, #перевод 100, Пользователю необходимо использовать Абонентское оборудование, поддерживающего функционал передачи
                SMS; 5) с помощью Перевода по SMS на карту с текстом следующего содержания: card номер_карты сумма_перевода Пользователю
                необходимо использовать Абонентское оборудование, поддерживающего функционал передачи SMS; 6) с помощью Платежной формы –
                необходимо иметь доступ к сети Интернет и следовать подсказкам Платежной формы; 7) с помощью Выдачи денежных средств с
                телефона МТС Пользователю необходимо использовать Абонентское оборудование, поддерживающего функционал передачи SMS, для
                того, чтобы направить SMS с текстом следующего содержания: RUB сумма списания; 8) с помощью «Технических средств»
                Поставщиков услуг Пользователю необходимо использовать предлагаемый Поставщиками функционал ( SMS, доступ к сети «Интернет»,
                другое), 9) с помощью ТВ-приставки – Пользователю необходимо иметь ТВ-приставку с доступом в сеть Интернет, 10) с помощью
                SMS-портала – Пользователю необходимо иметь абонентское оборудование, поддерживающее технологию передачи информации с
                помощью SMS, кроме того тарифный план Абонента и услуги связи, оказываемые Абоненту своим оператором, должны позволять
                передавать и получать SMS с короткого номера/на короткий номер 1212.
            </p>
            <p>
                2.3. В рамках Персонального кабинета Сервиса или Личного кабинета Абонента МТС Оператор обязуется предоставить возможность
                Пользователям регистрироваться и пользоваться Сервисом с помощью Сайта, Мобильного приложения, ТВ-приставки. Для
                использования Сервиса с помощью USSD-портала регистрация в Персональном/Личном кабинете должна предварительно производиться
                с помощью Сайта. Без такой регистрации использование сервиса «Кошелек МТС Деньги» с помощью USSD возможно Абоненту
                МТС-физическому лицу для оплаты в пользу Поставщиков с услуг с лицевого счета Абонента МТС. При отправке Перевода по SMS,
                Перевода по SMS на карту, Платежной формы, с помощью Технических средств Поставщиков услуг самостоятельная регистрация в
                Личном Кабинете сервиса «Кошелек МТС Деньги» не требуется.
            </p>
            <p>2.4. Регистрация в Персональном кабинете Сервиса осуществляется на основании ввода Номера и/или пароля.</p>
            <p>
                2.5. С момента Регистрации в Персональном кабинете Сервиса с помощью Мобильного приложения, ТВ-приставки, с момента выбора и
                указания в клиентском приложении или ином Техническом средстве Поставщиков услуг в качестве способа оплаты Номера телефона,
                c момента ввода команды USSD, отправки запроса ( SMS) на Выдачу денежных средств с телефона МТС, Перевода по SMS, Перевода
                по SMS на карту и нажатия Пользователем кнопки «Вызов» со своего Абонентского оборудования, с момента входа в Личный кабинет
                МТС и формирования Распоряжения на Сайте или на Платежной форме, используя Технические средства Поставщиков услуг, с момента
                отправки SMS (отправка команды в формате SMS) в порядке и формате, указанном в SMS, полученном через SMS-портал (при наличии
                зарегистрированной в Сервисе банковской карты) – Пользователь подтверждает полное и безоговорочное принятие условий
                настоящего Соглашения по пользованию Сервисом «Кошелек МТС Деньги». Пользователь вправе подать заявление об открытии для
                него возможности пользования сервисом «Кошелек МТС Деньги», включая «Мой кошелек», в салонах-магазинах МТС (полный перечень
                салонов-магазинов на сайте{' '}
                <Link href="http://www.mts.ru/" target="_blank" rel="nofollow">
                    www.mts.ru
                </Link>
                ), для чего Пользователю достаточно предъявить в салоне-магазине МТС документ, удостоверяющий личность Пользователя для
                целей проведения упрощенной идентификации Пользователя, при этом на абонентский номер Пользователя направляется SMS с номера
                1212. С момента отправки Пользователем ответного SMS с того же самого абонентского номера как номера на номер 1212,
                подтверждающего открытие для него сервиса «Кошелек МТС Деньги» и счета «Мой Кошелек», Пользователь подтверждает полное и
                безоговорочное принятие условий настоящего Соглашения по пользованию сервисом «Кошелек МТС Деньги», а также заверяет МТС о
                том, что указанный Пользователем абонентский номер выделен по соответствующему договору с оператором связи Пользователю.
                Пользователь вправе выразить свое полное и безоговорочное согласие с настоящим Соглашением путем подключения услуги
                «Автоплатеж» согласно{' '}
                <Link
                    href="http://static.mts.ru/uploadmsk/contents/1655/usloviya_predostavl_servisa_s_bk.pdf"
                    target="_blank"
                    rel="nofollow"
                >
                    Условиям предоставления сервиса «Кошелек МТС Деньги» с использованием средств на банковской карте
                </Link>
                ,{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_mts_pay.pdf" target="_blank" rel="nofollow">
                    Условиям предоставления сервиса «Кошелек МТС Деньги» при оплате с помощью денежных средств на лицевом счете
                </Link>{' '}
                (для Абонентов МТС – физических лиц),{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/usl_use_eletron_money.pdf" target="_blank" rel="nofollow">
                    Условиям предоставления сервиса «Кошелек МТС Деньги» с использованием электронных денежных средств ПАО «МТС Банк»
                </Link>{' '}
                . Соглашаясь с условиями настоящего Соглашения по пользованию Сервисом Пользователь понимает и соглашается с тем, что
                Пользователь использует Сервис через сеть «Интернет», не находящейся под контролем Оператора, и с использованием
                Абонентского оборудования, в силу чего, использование Сервиса может быть обусловлено определенными рисками. В целях
                устранения рисков Пользователь обязуется надлежащим образом исполнять условия настоящего Соглашения и предпринимать
                достаточные и необходимые меры, направленные на то, чтобы Абонентское оборудование Пользователя, а также пароли, не стали бы
                доступны третьим неуполномоченным лицам, а при необходимости замены SIM-карты в обязательном порядке Пользователь обязуется
                заполнять письменное заявление о смене SIM-карты (один экземпляр которого оставлять себе) и предъявлять Оператору (его
                представителю) оригинал документа, удостоверяющего личность.
            </p>
            <p>
                <strong>3. </strong>
                <strong>Условия пользования Сервисом: </strong>
            </p>
            <p>
                3.1. После первичной регистрации в Персональном кабинете Сервиса или регистрации в Личном кабинете Абонента МТС для
                пользования Сервисом в целях безопасности необходимо каждый раз проводить Авторизацию. Оператор исходит из презумпции того,
                что любые действия, совершенные на Сайте, из Мобильного приложения после ввода пароля, при заполнении Платежной формы,
                считаются действиями Пользователя и волеизъявлением Пользователя на пользование сервисом «Кошелек МТС Деньги» в соответствии
                с настоящим Соглашением; любые действия, совершенные Пользователем по пользованию сервисом «Кошелек МТС Деньги» с помощью
                USSD, SMS (Перевод по SMS, Перевод по SMS на карту, Выдача денежных средств с телефона МТС, SMS-портала), Технических
                средств Поставщиков услуг, с помощью ТВ-приставки, считаются действиями Пользователя и волеизъявлением Пользователя на
                пользование сервисом «Кошелек МТС Деньги» в соответствии с настоящим Соглашением.
            </p>
            <p>
                3.2. При Выдаче денежных средств с телефона МТС, Перевода по SMS, Перевода по SMS на карту, Сервис предоставляется с помощью
                денежных средств на лицевом счете Абонента МТС. При пользовании Сайтом «МТС Деньги» ({' '}
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>
                ), Мобильным приложением, Платежной формой, ТВ-приставкой Сервис предоставляется со всех доступных источников средств –
                банковской карты, лицевого счета мобильного телефона, счета «Мой кошелек». При пользовании SMS-порталом Сервис
                предоставляется с банковских карт, зарегистрированных в Сервисе. При пользовании USSD-порталом для оплаты товаров, работ,
                услуг Поставщиков услуг Сервис предоставляется с помощью денежных средств на лицевом счете Абонента МТС, счете «Мой
                кошелек», а также с банковских карт, зарегистрированных в Сервисе. При пользовании USSD-переводом, Сервис предоставляется со
                всех доступных источников, за исключением банковских карт.
            </p>
            <p>3.3. Автоматические платежи предоставляются в специализированном разделе Сайта, в Мобильном приложении.</p>
            <p>3.4. Услуга доступна для абонентов, в настройках телефона которого указан SMSЦентр: +79168999100.</p>
            <p>
                3.5. За совершение Операций и Платежей на Сайте «МТС Деньги» ({' '}
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>
                ) или в Мобильном приложении Абонентам (физическим лицам) или Пользователям (физическим лицам, когда Абонентский договор
                заключен с юридическим лицом или индивидуальным предпринимателем) МТС может быть начислен Кэшбэк в соответствии с{' '}
                <Link
                    href="http://static.mts.ru/upload/images/mts_cashback_rules.pdf?_ga=2.202815761.1258034112.1545043321-987147080.1545043321"
                    target="_blank"
                    rel="nofollow"
                >
                    Правилами программы «МТС Cashback»
                </Link>
                . Совершая Операции и Платежи, по которым в соответствии с Правилами программы начисляется Кэшбэк, Плательщик регистрируется
                в программе «МТС Cashback». Кэшбэк рассчитывается исходя из стоимости товаров, работ или услуг, оплаченных с использованием
                Сервиса, за исключением перечня операций по оплате товаров, работ или услуг, а также переводов денежных средств, по которым
                не рассчитывается Кэшбэк в соответствии с{' '}
                <Link
                    href="http://static.mts.ru/upload/images/mts_cashback_rules.pdf?_ga=2.202815761.1258034112.1545043321-987147080.1545043321"
                    target="_blank"
                    rel="nofollow"
                >
                    Правилами программы
                </Link>
                . Кэшбэк учитывается на виртуальной счетчике, привязанном к абонентскому номеру. Максимальный размер Кэшбэка (скидки) – 2
                000 руб. в месяц. Кэшбэк расходуется в соответствии с Правилами программы «МТС Cashback».
            </p>
            <p>
                3.6. По номеру *700# предоставляется только оплата с использованием средств с лицевого счета Абонента МТС – физического
                лица.
            </p>
            <p>
                3.7. Ввод Номера и Пароля для входа в USSD-портал или при отправке Перевода по SMS, Перевода по SMS на карту, Платежную
                форму, для Выдачи денежных средств с телефона МТС, для «Мобильного платежа», не требуется.
            </p>
            <p>3.8. При каждом входе на Сайт (в раздел Персонального кабинета), в Мобильное приложение необходимо авторизоваться.</p>
            <p>
                3.9. При первой попытке совершения платежа через ТВ-приставку Абоненту необходимо ввести Номер и код подтверждения,
                отправленный на данный Номер. При повторном совершении платежа необходимо ввести только код подтверждения.
            </p>
            <p>
                3.10. Правила использования пользовательских настроек в рамках Персонального кабинета Сервиса и возможности использования
                Сервиса с помощью Мобильного приложения, Сайта, USSD, ТВ-приставки, Выдачи денежных средств с телефона МТС, Перевода по SMS,
                Перевода по SMS на карту, Платежной формы:
            </p>
            <TableWrapper>
                <Table>
                    <tbody>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>Интерфейс</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <p>
                                    <strong>Платежные «инструменты» </strong>
                                </p>
                            </Cell>
                            <Cell>
                                <p>
                                    <strong>История Платежей</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <p>
                                    <strong>Избранные платежи (шаблоны)</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <p>
                                    <strong>Автоплатежи</strong>
                                </p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>Сайт «МТС Деньги»</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>Регистрация банковской карты</li>
                                    <li>Использование банковской карты, зарегистрированной на Сайте или в Мобильном приложении</li>
                                    <li>Оплата с зарегистрированной банковской карты</li>
                                    <li>Оплата с незарегистрированной банковской карты (ввод реквизитов карты при каждом Платеже)</li>
                                    <li>Оплата с лицевого счета Абонента МТС-физического лица</li>
                                    <li>Оплата со счета «Мой Кошелек»</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>
                                    Список совершенных Платежей в Сервисе, выполненных с помощью Платежной формы, Сайта и Мобильного
                                    приложения «МТС Деньги»
                                </p>
                            </Cell>
                            <Cell>
                                <p>
                                    Просмотр списка, создание, редактирование, удаление избранных платежей, сохраненных на Сайте и в
                                    Мобильном Приложении «МТС Деньги»
                                </p>
                            </Cell>
                            <Cell>
                                <p>
                                    Просмотр списка, подключение, редактирование, удаление Автоплатежей, приостановка и возобновление их
                                    действия
                                </p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>Мобильное приложение «МТС Деньги», «Мой МТС»</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>Регистрация банковской карты</li>
                                    <li>Использование банковской карты, зарегистрированной на Сайте или в Мобильном приложении</li>
                                    <li>Оплата с зарегистрированной банковской карты</li>
                                    <li>Оплата с незарегистрированной банковской карты (ввод реквизитов карты при каждом Платеже)</li>
                                    <li>Оплата с лицевого счета Абонента МТС-физического лица</li>
                                    <li>Оплата со счета «Мой Кошелек»</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>
                                    Список совершенных Платежей в Сервисе, выполненных с помощью Платежной формы, Сайта и Мобильного
                                    приложения «МТС Деньги» (МТС Деньги);
                                </p>
                                <p>Нет (Мой МТС)</p>
                            </Cell>
                            <Cell>
                                <p>
                                    Общий список избранных платежей (шаблонов), создание, редактирование, удаление шаблонов (МТС Деньги),
                                    оплата;
                                </p>
                                <p>Нет (Мой МТС)</p>
                            </Cell>
                            <Cell>
                                <p>
                                    Просмотр списка, подключение, редактирование, удаление Автоплатежей, приостановка и возобновление их
                                    действия
                                </p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>USSD</strong>
                                    <strong>-портал</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>
                                        (только *115#, *700#, *611#) Оплата с лицевого счета Абонента МТС-физического лица (только *115 #)
                                    </li>
                                    <li>Оплата с зарегистрированной банковской карты</li>
                                    <li>Оплата со счета «Мой Кошелек»</li>
                                    <li>Перевод с лицевого счета Абонента МТС-физического лица</li>
                                    <li>Перевод со счета «Мой Кошелек»</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>
                                    Просмотр списка избранных платежей, сохраненных на Сайте и в Мобильном Приложении «МТС Деньги», оплата
                                </p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>Перевод по SMS/на карту, Выдача денежных средств с телефона МТС </strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>Оплата с лицевого счета Абонента МТС-физического лица</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>Платежная форма</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>Оплата с лицевого счета Абонента МТС-физического лица</li>
                                    <li>Использование банковской карты, зарегистрированной на Сайте или в Мобильном приложении</li>
                                    <li>Оплата со счета «Мой Кошелек»</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>ТВ-приставка </strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>Регистрация банковской карты</li>
                                    <li>Использование банковской карты, зарегистрированной на Сайте или в Мобильном приложении</li>
                                    <li>Оплата с зарегистрированной банковской карты</li>
                                    <li>Оплата с незарегистрированной банковской карты (ввод реквизитов карты при каждом Платеже)</li>
                                    <li>Оплата с лицевого счета Абонента МТС-физического лица</li>
                                    <li>Оплата со счета «Мой Кошелек»</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                        </tr>
                        <tr>
                            <Cell>
                                <p>
                                    <strong>SMS-</strong>
                                    <strong>портал</strong>
                                </p>
                            </Cell>
                            <Cell>
                                <List>
                                    <li>Использование банковской карты, зарегистрированной на Сайте или в Мобильном приложении</li>
                                </List>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Нет</p>
                            </Cell>
                            <Cell>
                                <p>Подключение Автоплатежей</p>
                            </Cell>
                        </tr>
                    </tbody>
                </Table>
            </TableWrapper>
            <p>
                3.11. Пользователь уведомлен и согласен с тем, что информация о балансе лицевого счета в мобильном приложении «МТС Деньги» и
                на Сайте может обновляться в течение одного часа с момента исполнения Распоряжения Пользователя.
            </p>
            <p>
                3.12. В целях предотвращения несанкционированного доступа к данным, необходимым для пользования Сервиса, в т.ч. сохраненным
                в Персональном кабинете Сервиса, действуют ряд ограничений:
            </p>
            <p>
                3.12.1. После операций по замене Номера (в т.ч. в случае, если Оператору становится известно о замене Пользователя, когда
                Номер выделен Абоненту – юридическому лицу), расторжения абонентского договора с МТС или передачи прав и обязанностей по
                абонентскому договору третьему лицу все данные Пользователя о его зарегистрированных банковских картах и Платежах, а также
                любая иная информация, хранившаяся в Персональном кабинете Сервиса, автоматически удаляется из Персонального кабинета.
                Удаление указанной информации после замены Номера, а также расторжения абонентского договора с МТС происходит в течение
                суток, после смены владельца – в течение пяти суток после окончания данной процедуры.
            </p>
            <p>
                3.12.2. После операций по замене SIM-карты (кроме случая, когда замена связана со сменой владельца (см. п. 3.11.1))
                Пользователю в связи с технологическими особенностями в течение 24 часов недоступны следующие действия в Сервисе:
            </p>
            <p>a. Платежи с лицевого счета МТС, с банковских карт Пользователя и со счета «Мой Кошелек» по направлениям:</p>
            <p>- пополнение электронных кошельков (например, QIWI VISA Wallet, Яндекс.Деньги и пр.);</p>
            <p>- переводы средств в системы денежных переводов;</p>
            <p>- переводы средств на банковские карты и счета;</p>
            <p>- погашение кредитов;</p>
            <p>- платежи в пользу операторов услуг подвижной радиотелефонной (сотовой) связи;</p>
            <p>- переводы на номера МТС, Билайн, Мегафон, Теле2, в т.ч. в рамках услуги «Перевод по SMS», «Перевод по SMS на карту».</p>
            <p>
                Пользователю также в течение указанного времени недоступны услуги «Перевод по SMS», «Перевод по SMS на карту», «Выдача
                денежных средств с телефона МТС».
            </p>
            <p>b. Изменять пользовательские настройки и данные в Персональном кабинете:</p>
            <p>- добавление, переименование и удаление банковских карт, «Мой Кошелек»;</p>
            <p>- добавление, переименование и удаление шаблонов платежей;</p>
            <p>- добавление, переименование и удаление Автоплатежей;</p>
            <p>- регистрация в Сервисе через Мобильное приложение для Абонентов/Пользователей, которые ранее не пользовались Сервисом.</p>
            <p>
                3.13. Пользователь при пользовании Сервисом вправе потребовать передачи ему в электронном виде кассового чека при оплате
                Абонентом по соответствующему договору, заключенному между Абонентом/Пользователем и ПАО «МТС», АО «Сибинтертелеком», ПАО
                МГТС, ООО «ЦТВ». Для указанных целей Пользователю необходимо до осуществления расчетов указать Номер или адрес электронной
                почты, на который необходимо отправить кассовый чек. Стороны пришли к соглашению о том, что, если Пользователь при
                предоставлении ему такой возможности не указывает Номер или адрес электронной почты, Пользователь выражает таким образом
                отказ от получения чека, при этом, если соответствии с требованиями действующего законодательства при увеличении остатка
                электронных денежных средств за счет денежных средств с Лицевого счета Пользователю необходимо направить/обеспечить
                направление кассового чека с признаком «возврат прихода», таковой направляется на Номер, с Лицевого счета которого были
                списаны денежные средства на сумму уменьшения остатка денежных средств с Лицевого счета. Стороны пришли к соглашению о том,
                что если Пользователь указывает Номер или адрес электронной почты, Пользователь тем самым заверяет МТС о принадлежности
                указанного Номера или адреса электронной почты Пользователю, выражает свое полное и безоговорочное согласие на получение на
                указанные Пользователем Номер или адрес электронной почты кассового чека (в т.ч. путем направления ему короткого текстового
                сообщения или электронного письма, содержащего гиперссылку для получения более полной информации), а также выражает полное и
                безоговорочное согласие на обработку персональных данных Пользователя и сведений об абоненте (включая передачу таких
                персональных данных и(или) сведений об абоненте оператору фискальных данных и(или) иным лицам, с которым у МТС заключены
                соответствующие договоры), связанных с необходимостью направления Пользователю указанного кассового чека на адрес
                электронной почты или Номер (а именно: абонентский номер, адрес электронной почты, сумма платежа, дата платежа и иные
                данные, позволяющие выполнить МТС обязанность по направлению/обеспечения направления кассового чека). Перечень обработчиков
                персональных данных содержится на сайте МТС в разделе «Партнеры». Обработка персональных данных и отзыв предоставленного
                согласия на обработку персональных данных осуществляются в порядке, предусмотренном настоящим Соглашением. Пользователь
                полностью несет риск корректности указанных Пользователем Номера или адреса электронной почты.
            </p>
            <p>
                3.14. Пользователь вправе в любое время в одностороннем внесудебном порядке отказаться от использования Сервиса. В целях
                отказа от пользования Сервисом с помощью Мобильного Приложения Пользователь должен удалить все зарегистрированные данные о
                банковских картах, обнулить счет «Мой Кошелек»; при отказе от использования Сервиса через Сайт, Пользователь должен удалить
                из Личного кабинета Абонента МТС все данные о зарегистрированных банковских картах, обнулить счет «Мой Кошелек». Удаляя
                данные банковских карт и обнуляя счет «Мой Кошелек» из Мобильного приложения «МТС Деньги», и(или) из Личного кабинета
                Абонента МТС, Пользователь также отказывается от использования Сервиса с помощью USSD, Мобильного приложения «Мой МТС»,
                ТВ-приставки, Перевода по SMS, Перевода по SMS на карту, Платежной формы, Выдачи денежных средств с телефона МТС,
                Технических средств поставщиков (при этом в отношении остатка ЭДС на счете «Мой Кошелек» Пользователь руководствуется{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Условиями ПАО «МТС-Банк» о выпуске и обслуживании ЭСП для сервиса «Кошелек МТС Деньги»
                </Link>
                ), SMS-портала. Пользователь вправе в любое время начать пользоваться Сервисом после отказа от его использования путем
                принятия настоящего Соглашения в порядке, указанном в п. 2.5. настоящего Соглашения.
            </p>
            <p>
                3.15.{' '}
                <strong>
                    Особенности использования Сервиса для доступа к системам дистанционного банковского обслуживания (ДБО) ПАО «МТС-Банк»
                    для Пользователей, заключивших с ПАО «МТС-Банк» договор.{' '}
                </strong>
            </p>
            <p>
                3.15.1. Пользователи, заключившие с ПАО «МТС-Банк» договор комплексного банковского обслуживания (далее – «ДКО») имеют
                возможность использовать Сервис для доступа к Системе «Интернет банк»/«Мобильный банк» ПАО «МТС-Банк» («Система ИБ/МБ»).
                Система ИБ/МБ – это программно-аппаратный комплекс Банка, обеспечивающий дистанционное банковское обслуживание Клиентов
                посредством сети Интернет и с помощью мобильного телефона. Доступ к системе предоставляется либо через мобильное приложение
                или сайт ПАО «МТС-Банк», либо с использованием Сервиса ПАО «МТС».
            </p>
            <p>
                3.15.2. Сервис позволяет Пользователям направлять в ПАО «МТС» или ПАО «МТС-Банк» юридически значимые заявления, а также
                получать от ПАО «МТС» или ПАО «МТС-Банк» конфиденциальную информацию. Обмениваясь информацией с ПАО «МТС» или ПАО «МТС-Банк»
                с помощью Сервиса, Пользователь действует соответственно либо в рамках договора с ПАО «МТС», либо в рамках договора с ПАО
                «МТС-Банк». Разделы интерфейса Сервиса, которые позволяют Пользователю направлять юридически значимые заявления в ПАО
                «МТС-Банк», либо получать информацию из Системы ИБ/МБ (далее - <strong>«Раздел Сервиса для услуг Банка» </strong>)
                предназначены для взаимодействия ПАО «МТС-Банк» с Пользователями в рамках заключённых между ними договоров.
            </p>
            <p>
                3.15.3. Положения настоящего Соглашения не регулируют правоотношения между Пользователем и ПАО «МТС-Банк». Права и
                обязанности Пользователя в отношениях с ПАО «МТС-Банк» закреплены в рамках заключённых Пользователем с ПАО «МТС-Банк»
                договоров.
            </p>
            <p>
                3.15.4. В связи с тем, что ПАО «МТС» является официальным поставщиком платёжного приложения с использованием которого
                Пользователи, являющиеся клиентами ПАО «МТС-Банк», вправе получить доступ к Системе ИБ/МБ, настоящее Соглашение имеет
                значение в правоотношениях Пользователей с ПАО «МТС-Банк» только в части определения порядка доступа к Сервису для
                использования Раздела Сервиса для услуг Банка.
            </p>
            <p>
                3.15.5. Возможности Сервиса для использования Раздела Сервиса для услуг Банка могут быть реализованы Пользователем через
                Мобильное Приложение «МТС-Банк» или Сайт.
            </p>
            <p>
                3.15.6. В том случае, если положения иных разделов настоящего Соглашения при определении порядка доступа к Сервису для
                использования Раздела Сервиса для услуг Банка будут противоречить положениям настоящего раздела («Особенности использования
                Сервиса для доступа к системам дистанционного банковского обслуживания (ДБО) ПАО «МТС-Банк» для Пользователей, заключивших с
                ПАО «МТС-Банк» договор»), применяются специальные условия, установленные в настоящем разделе.
            </p>
            <p>
                <strong>4. </strong>
                <strong>Порядок регистрации в Персональном кабинете Сервиса: </strong>
            </p>
            <p>
                4.1. <strong>В Мобильном приложении «МТС-Деньги»: </strong>
            </p>
            <p>
                Вы можете загрузить приложение из App Store, Google Play или Магазина приложений МТС на сайте{' '}
                <Link href="http://apps.mts.ru" target="_blank" rel="nofollow">
                    http://apps.mts.ru
                </Link>
                .
            </p>
            <p>
                Откройте Мобильное приложение, введите абонентский номер и полученный в SMS пароль. Для удобства вы можете установить код
                доступа из 4 цифр. Если позволяет ваше Абонентское оборудование вы можете настроить вход в Мобильное приложение с
                использованием биометрических данных.
            </p>
            <p>
                4.2. <strong>На Сайте: </strong>
            </p>
            <p>
                Наберите в адресной строке браузера{' '}
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>{' '}
                (
                <Link href="http://www.pay.mts.ru/" target="_blank" rel="nofollow">
                    www.pay.mts.ru
                </Link>{' '}
                или{' '}
                <Link href="http://www.dengi.mts.ru/" target="_blank" rel="nofollow">
                    www.dengi.mts.ru
                </Link>
                ) для перехода на Сайт «МТС Деньги». Откроется главная страница Сервиса.
            </p>
            <p>Нажмите кнопку «Войти» и введите Абонентский номер и полученный в SMS пароль.</p>
            <p>
                4.3. <strong>В </strong>
                <strong>USSD</strong>
                <strong>-портале: </strong>
            </p>
            <p>
                Регистрации при пользовании USSD-порталом не требуется. Для совершения Платежей с банковской карты с помощью USSD-портала
                необходимо предварительно зарегистрировать банковскую карту на Сайте или в Мобильной приложении.
            </p>
            <p>
                4.4. <strong>В ТВ-приставке: </strong>
            </p>
            <p>
                Включите ТВ-приставку, выберите раздел «Кошелек МТС Деньги» (либо зайдите на любое приложение внутри ТВ-приставки, например,
                онлайн кинотеатр, выберите понравившийся фильм и нажмите кнопку «купить /оформить подписку»). В открывшемся разделе «Кошелек
                МТС Деньги» введите свой Номер и код подтверждения из SMS, который будет выслан на указанный Номер. При регистрации Абонента
                осуществляется связка «Номер + Лицевой счет Абонента МТС + Серийный номер ТВ-приставки».
            </p>
            <p>
                4.5. <strong>В Переводе по SMS, Переводе по </strong>
                <strong>SMS</strong>
                <strong> на карту,</strong>{' '}
                <strong>
                    при Выдаче денежных средств с телефона МТС, на Платежной форме, в Технических средствах Поставщиков услуг, в «Мобильном
                    платеже»{' '}
                </strong>{' '}
                регистрация не требуется.
            </p>
            <p>
                4.6. <strong>Перед использованием </strong>
                <strong>SMS</strong>
                <strong>-портала </strong>необходимо предварительно зарегистрироваться на Сайте или в Мобильном приложении, зарегистрировав
                банковскую карту (банковские карты) в соответствии с{' '}
                <Link
                    href="http://static.mts.ru/uploadmsk/contents/1655/usloviya_predostavl_servisa_s_bk.pdf"
                    target="_blank"
                    rel="nofollow"
                >
                    Условиями предоставления сервиса «Кошелек МТС Деньги» с использованием денежных средств на банковской карте
                </Link>
                .
            </p>
            <p>
                <strong>5. </strong>
                <strong> Порядок авторизации в Персональном кабинете сервиса «Кошелек МТС Деньги»: </strong>
            </p>
            <p>
                5.1. <strong>На Сайте, а также в Мобильном приложении «МТС-Деньги»: </strong>
            </p>
            <p>
                Нажмите кнопку «Войти». Откроется страница авторизации, на которой необходимо ввести абонентский номер и полученный в SMS
                пароль.
            </p>
            <p>
                5.2. <strong>В </strong>
                <strong>USSD</strong>
                <strong>-портале: </strong>
            </p>
            <p>
                Авторизации при пользовании USSD-порталом не требуется. Для входа в USSD-портал достаточно на телефоне/смартфоне набрать
                команду *115# (, *700#( или *611#(.
            </p>
            <p>
                5.3. <strong>В ТВ-приставке: </strong>
            </p>
            <p>
                Выберите раздел «Кошелек МТС Деньги» и введите свой Номер. Авторизация Абонента осуществляется по связке «Номер + Лицевой
                счет Абонента МТС + Серийный номер ТВ-приставки».
            </p>
            <p>
                5.4. <strong>В Переводе по SMS, Переводе по </strong>
                <strong>SMS</strong>
                <strong>
                    {' '}
                    на карту, а также при Выдачи денежных средств с телефона МТС, на Платежной форме, в «Мобильном платеже», при пользовании{' '}
                </strong>
                <strong>SMS</strong>
                <strong>-порталом </strong>авторизация не требуется.
            </p>
            <p>
                5.5. <strong>В Технических средствах Поставщиков услуг: </strong>
            </p>
            <p>Авторизация запрашивается в соответствии с условиями предоставления услуг на ресурсах Поставщиков услуг.</p>
            <p>
                <strong>6. </strong>
                <strong>
                    Особенности перевода денежных средств лицу, не являющемуся Пользователем сервиса «Кошелек МТС Деньги» на момент
                    перевода:{' '}
                </strong>
            </p>
            <p>
                6.1. В целях осуществления перевода денежных средств лицу, не являющемуся Пользователем сервиса «Кошелек МТС Деньги»,
                Пользователю необходимо пройти Авторизацию в сервисе, заполнить форму перевода с обязательным указанием номера мобильного
                телефона получателя, суммы перевода и выбрать источник списания денежных средств.
            </p>
            <p>
                6.2. Подтверждением перевода Пользователь дает Распоряжение на осуществление перевода денежных средств со счета списания в
                пользу другого лица, путем списания с выбранного источника списания и зачисления на счет «Мой кошелек» Пользователя (для
                платежей с лицевого счета МТС и банковской карты) и последующего холдирования (временной блокировки) путем создания
                Пользователю временного ЭСП HOLD в соответствии с{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Общими условиями осуществления перевода электронных денежных средств и обслуживания электронных средств платежа ПАО
                    «МТС-Банк»
                </Link>{' '}
                денежных средств на данном счете (для всех источников списания) на 72 (семьдесят два) часа с момента подтверждения перевода.
                Минимальная сумма перевода составляет 50 (пятьдесят) рублей. Лимит по максимальной сумме нахождения денежных средств на ЭСП
                HOLD составляет 60 000 (шестьдесят) тысяч рублей единовременно. Суммарный лимит в месяц составляет 120 000 (сто двадцать)
                тысяч рублей.
            </p>
            <p>
                6.3. В рамках выполнения перевода с Пользователя может взиматься комиссионное вознаграждение, в зависимости от источника
                средств.
            </p>
            <p>
                6.4. Воспользоваться переводом в пользу лица, не являющегося Пользователем сервиса «Кошелек МТС Деньги» могут Пользователи,
                прошедшие процедуру Упрощенной идентификации или Идентификации; не допускаются переводы для Пользователей, не прошедших
                Упрощенную идентификацию или Идентификацию в Сервисе.
            </p>
            <p>
                6.5. Для получения денежных средств получателю необходимо осуществить Регистрацию в сервисе «Кошелек МТС Деньги» на
                условиях, указанных в настоящем Соглашении. Регистрацию необходимо осуществить в срок не позднее 72 (семидесяти двух) часов
                с момента отправления перевода Пользователем.
            </p>
            <p>
                6.6. После осуществления Регистрации получателю денежных средств необходимо осуществить действия по зачислению переведенных
                денежных средств. Данные действия считаются Распоряжением от имени Пользователя на зачисление холдированых денежных средств
                со счета «Мой кошелек» на выбранный получателем счет зачисления. Зачисление денежных средств на счет «Мой кошелек» не
                доступно, если получатель не прошел процедуру Упрощенной идентификации или Идентификации в Сервисе.
            </p>
            <p>
                6.7. До момента получения средств Пользователь вправе отозвать перевод посредством Сайта или Мобильного приложения «МТС
                Деньги». По истечении 72 (семидесяти двух) часов в случае не востребованности перевода, денежные средства автоматически
                возвращаются Пользователю сервиса, который отправил перевод, на тот источник платежа, с которого был инициирован перевод.
            </p>
            <p>
                <strong>7. </strong>
                <strong> Порядок закрытия Сервиса </strong>
            </p>
            <p>7.1. МТС вправе закрыть Сервис для Пользователя, если Пользователь не совершал Платежей в течение 3 (трех) лет.</p>
            <p>
                7.2. При закрытии Сервиса все банковские карты, счет «Мой Кошелек», настройки Пользователя удалятся в целях безопасности.
                Пользователь вправе начать пользоваться Сервисом снова в соответствии с условиями настоящего Соглашения.
            </p>
            <p>
                7.3. Если при закрытии Сервиса «Кошелек МТС Деньги» у Пользователя на счете «Мой Кошелек» оставались денежные средства, то
                они будут переведены на счет абонентского телефона - идентификатора (если это возможно) в течение 45 дней. Если Пользователь
                не является действующим абонентом оператора связи, то денежные средства будут переведены в доход Эмитента ЭДС (согласно{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Договору на выпуск и обслуживание ЭСП для Сервиса «Кошелек МТС Деньги»
                </Link>
                ).
            </p>
            <p>
                <strong>8. </strong>
                <strong>Права и обязанности сторон:</strong>
            </p>
            <p>
                8.1. <strong>Оператор обязан: </strong>
            </p>
            <p>8.1.1. Обеспечить Пользователю доступ в Персональный кабинет Сервиса, в соответствии с условиями настоящего Соглашения.</p>
            <p>
                8.1.2. Не разглашать, не передавать информацию третьим лицам о Пользователе и совершенных Пользователем Платежах с помощью
                сервиса «Кошелек МТС Деньги», за исключением случаев, предусмотренных действующим законодательством Российской Федерации.
            </p>
            <p>
                8.2. <strong>Оператор имеет право:</strong>
            </p>
            <p>
                8.2.1. Отказать Пользователю в оказании Сервиса в случае нарушения Пользователем обязательств, предусмотренных настоящим
                Соглашением.
            </p>
            <p>
                8.2.2. Отказать Пользователю в авторизации в Сервисе, в случае трехкратного некорректного ввода полученного в SMS пароля.
                Для дальнейшего использования Сервиса необходимо дождаться разблокировки аккаунта Пользователя. Время блокировки при
                превышении лимита запросов пароля в SMS равно пяти минутам.
            </p>
            <p>
                8.2.3. Отказать Пользователю в проведении Операции и Платежа, если он не прошел процедуру Упрощенной идентификации в
                случаях, когда такая процедура поручена Оператору участвующей в Сервисе кредитной организацией.
            </p>
            <p>
                8.2.4. В целях защиты прав Пользователя Оператор в любой момент времени вправе ограничить Пользователя в функционале Сервиса
                вплоть до посещения Пользователем салона-магазина, работающего под товарным знаком МТС, в целях проведения идентификации
                Пользователя.
            </p>
            <p>
                8.3. <strong>Оператор не несет ответственность: </strong>
            </p>
            <p>
                8.3.1. За последствия несанкционированного использования Сервиса третьими лицами без ведома Пользователя, в т.ч. в случае
                утраты Пользователем Абонентского оборудования и(или) в случае получения третьими лицами доступа к Паролю, коду доступа к
                Сервису Пользователя.
            </p>
            <p>
                8.3.2. За прямой или косвенный ущерб, понесенный Пользователем в результате ошибок, пропусков, перерывов в работе, изменения
                функций, дефектов, задержек в работе, случившихся не по вине Оператора.
            </p>
            <p>
                8.4. <strong>Пользователь обязан: </strong>
            </p>
            <p>
                8.4.1. Обеспечить настройку и защиту от несанкционированного использования своего Абонентского оборудования, а также
                оперативно реагировать при обнаружении случаев такого использования.
            </p>
            <p>
                8.4.2. Незамедлительно сообщать МТС об утере, краже, пропаже SIM-карты, так как Абонент несет все обязательства по
                сформированным Распоряжениям и Платежам, вплоть до момента получения МТС от Абонента письменного заявления об утрате
                SIM-карты (или Абонентского оборудования с SIM-картой), влекущего прекращение обслуживания данной SIM-карты МТС и,
                соответственно, невозможность ее дальнейшего использования по назначению.
            </p>
            <p>
                8.4.3. Пользователь обязуется нести полную ответственность за все обращения и действия, предпринятые через Персональный
                кабинет Сервиса, имевшие место после регистрации Пользователя в Личном кабинете МТС.
            </p>
            <p>
                8.4.4. Пользователь несет полную ответственность за любое нарушение обязательств, установленных настоящим Соглашением, а
                также за все последствия таких нарушений (включая любые убытки или ущерб, которые может понести Оператор)
            </p>
            <p>
                8.4.5. Не использовать Персональный кабинет Сервиса для совершения каких-либо действий, противоречащих действующему
                законодательству и препятствующих другим Пользователям соблюдать условия настоящего Соглашения.
            </p>
            <p>
                8.4.6. Пользователь обязан своевременно знакомиться с Соглашением и Условиями перед началом каждого использования Сервиса.
            </p>
            <p>
                8.5. <strong>Пользователь имеет право: </strong>
            </p>
            <p>8.5.1. Пользоваться Сервисом в соответствии с настоящим Соглашением.</p>
            <p>
                8.5.2. В случае возникновения у Пользователя претензий, пожеланий, связанных с предоставлением Сервиса, оформить
                соответствующее заявление (рекламацию) по обращению в Контактный центр по телефону 8 800 250 0890 или в{' '}
                <Link href="https://moskva.mts.ru/personal/podderzhka/zoni-obsluzhivaniya/offices" target="_blank" rel="nofollow">
                    салоны-магазины МТС
                </Link>
                . Особенности подачи претензии (обращения) и их рассмотрения могут быть установлены в Условиях, которыми следует
                руководствоваться при подаче таких претензий (обращений).
            </p>
            <p>
                <strong>9. </strong>
                <strong>Прочие условия </strong>
            </p>
            <p>
                9.1. Платежные услуги в сервисе «Кошелек МТС Деньги» оказываются ПАО «МТС Банк». Ген. лицензия ЦБ РФ № 2268 от 17.12.14.
                Услуга переводов денежных средств между физическими лицами доступна для пользователей сервиса «Кошелек МТС Деньги»,
                прошедших процедуру идентификации. Об остальных особенностях сервиса на сайте{' '}
                <Link href="http://www.mts.ru/" target="_blank" rel="nofollow">
                    www.mts.ru
                </Link>
                .
            </p>
            <p>
                9.2. В случае возникновения обстоятельств непреодолимой силы, прямо или косвенно запрещающих, или препятствующих выполнению
                своих обязательств, Оператор освобождается от ответственности за неисполнение или ненадлежащие исполнение взятых на себя
                обязательств.
            </p>
            <p>
                9.3. МТС вправе в одностороннем порядке уточнять и(или) дополнять настоящее Соглашение (включая Условия) путем размещения
                новой версии Соглашения и(или) Условий за 10 дней до введения изменений на сайте{' '}
                <Link href="http://www.mts.ru/" target="_blank" rel="nofollow">
                    www.mts.ru
                </Link>
                . Пользователь обязуется своевременно знакомиться с настоящим Соглашением и Условиями и несет риск несвоевременного
                ознакомления со всеми условиями использования Сервиса.
            </p>
            <p>
                9.4. На период действия настоящего Соглашения, а также на период до истечения сроков исковой давности по обязательствам,
                вытекающим из Соглашения, Пользователь (применимо для физических лиц) добровольно выражает свое согласие на обработку МТС и
                на передачу (распространение, предоставление, доступ) третьим лицам (в т.ч. ПАО «МТС-Банк», адрес места нахождения 115432,
                г. Москва, пр-т Андропова, д.18 корп.1) для обработки следующих персональных данных и сведений о Пользователе (далее в целом
                «персональные данные»):
            </p>
            <p>
                - фамилия, имя, отчество Пользователя, данные о документе, удостоверяющем личность Пользователя, дата рождения Пользователя,
                адрес места жительства и адрес места установки Пользовательского оборудования, абонентские номера, иные сведения,
                позволяющие прямо или косвенно идентифицировать Пользователя или его Пользовательское оборудование, сведения о
                Распоряжениях, Операциях и Платежах, остатках и движении денежных средств, используемых в Сервисе.
            </p>
            <p>
                Под обработкой персональных данных следует понимать действия (операции) или совокупность действий (операций), совершаемых с
                использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            </p>
            <p>
                Пользователь выражает свое согласие на обработку его персональных данных и (или) передачу сведений о Пользователе в целях:
            </p>
            <p>
                а) заключения и(или) исполнения Соглашения, в том числе (но не ограничиваясь) для осуществления третьими лицами абонентского
                и(или) сервисного обслуживания;
            </p>
            <p>
                б) в целях исполнения Соглашения третьим лицом – кредитной организацией, процессинговым центром, иным лицам, участвующим в
                реализации для Пользователя Сервиса «Кошелек МТС Деньги», в т. ч. в случае уступки прав и обязанностей по Соглашению МТС
                третьему лицу;
            </p>
            <p>
                в) в целях осуществления необходимых действий для взыскания с Пользователя задолженности за полученные услуги, предъявления
                иных требований к Пользователю, в случае неисполнения (ненадлежащего исполнения Пользователем обязательств по Соглашению), в
                т. ч. в случае уступки прав (требований), вытекающих из Соглашения, третьим лицам;
            </p>
            <p>
                г) в целях рассмотрения претензий, предъявляемых Пользователем как самому МТС, так и третьим лицам (агентам МТС, иным лицам,
                осуществляющим на основании договора с МТС абонентское и(или) сервисное обслуживание, реализацию Сервиса «Кошелек МТС
                Деньги»);
            </p>
            <p>
                д) в целях информационно-справочного обслуживания, в т. ч. для подготовки и распространения информации различными способами,
                в частности на магнитных носителях и с использованием средств телекоммуникаций, для оказания справочных и иных
                информационных услуг МТС и (или) третьими лицами.
            </p>
            <p>
                Перечень уполномоченных лиц, осуществляющих обработку персональных данных Пользователя и(или) выполняющих иные функции в
                соответствии с настоящим пунктом (далее «Уполномоченные лица»), а также адреса места нахождения таких лиц, указаны на сайте
                МТС, а также доводятся до сведений Пользователей в местах обслуживания Пользователей МТС, в т. ч. в момент принятия
                Пользователем условий настоящего Соглашения.
            </p>
            <p>
                Указанное в настоящем пункте согласие на обработку персональных данных Пользователей и на передачу Персональных данных
                третьим лицам не применяется в части осуществления платежей с лицевого счета в отношении Пользователей – Абонентов МТС
                (физических лиц) только в том случае, если Пользователь при заключении Абонентского договора с МТС либо в течение срока
                действия такого Абонентского договора в порядке, предусмотренном Абонентским договором, отказался (отозвал свое согласие) от
                обработки персональных данных, в т.ч. от передачи персональных данных Пользователя в адрес третьих лиц. В указанном случае
                Пользователь принимает и соглашается с тем, что Пользователю будет недоступен Сервис «Кошелек МТС Деньги» в части
                осуществления Платежей с лицевого счета.
            </p>
            <p>
                9.5. Пользователь вправе в любое время отозвать свое согласие на обработку его персональных данных, и(или) сведений о
                Пользователе, предоставленное в соответствии с п. 9.4. настоящего Соглашения, путем направления в МТС письменного заявления
                об отказе от обработки его персональных данных. При этом Пользователь принимает во внимание, что в соответствии с
                действующим законодательством о персональных данных, согласие Пользователя на обработку персональных данных не требуется в
                случае, если обработка персональных данных осуществляется в целях исполнения настоящего Соглашения, а также в случае, если
                обработка персональных данных необходима для осуществления прав и законных интересов оператора, осуществляющего обработку
                персональных данных, или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются
                права и свободы субъекта персональных данных.
            </p>
            <p>
                9.6. Настоящим пользователь вне зависимости от его иных волеизъявлений подтверждает, что он уведомлен и согласен, что при
                пользовании Сервисом в автоматическом режиме и без привязки к личности Пользователя обрабатывается техническая информация о
                пользовании Сервисом, включая, но не ограничиваясь, данные об IP-адресе, об оборудовании и программном обеспечении, включая
                параметры его использования и оказанных услугах в результате использования Сервиса, в том числе временные, информацию о
                фактах и причинах обращения к МТС, результатах их выполнения, информацию о заказанных услугах, об Операциях и(или) Платежах,
                об остатках и движении денежных средств для целей пользования Сервисом, о трафике, задолженности перед МТС и(или) третьими
                лицами, участие которых опосредовано использованием Сервиса, блокировках, информация о среднем объеме потребления услуг
                связи и средних начислениях за услуги связи, связанные с использованием Сервиса, данные о привязке абонентского устройства к
                оборудованию сети связи при пользовании Сервисом, адреса запрашиваемых страниц, уникальные идентификационные номера
                оборудования и установленных в нем идентификационных модулей и иная подобная информация. Указанная информация обрабатывается
                в том числе с привлечением Партнеров, а также аффилированных и иным образом связанных с оператором лиц для сбора
                статистической информации, обслуживания, выполнения обязательств МТС перед Пользователем, для предоставления Пользователю
                персонализированных сервисов, защиты его и других Пользователей от мошенничества и иных правонарушений, в исследовательских,
                маркетинговых, аналитических целях и для разработки, предложения и оказания новых Сервисов. Правила использования описанной
                информации в иных сервисах могут отличаться, выбор того или иного сервисам осуществляется пользователем самостоятельно
                исходя из требуемых условий его оказания.
            </p>
            <p>
                При пользовании Сервисом «Кошелек МТС Деньги» с помощью Сайта МТС www.mts.ru (включая{' '}
                <Link href="https://payment.mts.ru/" target="_blank" rel="nofollow">
                    https://payment.mts.ru/
                </Link>
                ) вы соглашаетесь с тем, что применимой будет являться{' '}
                <Link href="https://moskva.mts.ru/personal/cookie/" target="_blank" rel="nofollow">
                    Политика обработки файлов cookie
                </Link>{' '}
                и может взиматься плата за интернет трафик согласно тарифного плана Абонента за передачу данных, хранящихся в cookie (=куки)
                браузера по скриптам Google analytics (Гугл Аналитикс), Яндекс Метрика.
            </p>
            <p>
                9.7. В случае расторжения Абонентского договора об оказании услуг связи (для целей телерадиовещания) по фиксированным сетям
                ПАО «МТС» связка «Номер + Лицевой счет Абонента МТС + Серийный номер ТВ-приставки» становится неактивной, и Абонент не может
                воспользоваться Сервисом.
            </p>
            <p>
                9.8. Учитывая, что правообладателем Мобильного приложения «МТС Деньги» и «Мой МТС» является ПАО «МТС» (Оператор), на
                основании ст. 1280 Гражданского кодекса РФ, Пользователю предоставляется право установить на свое Абонентское оборудование и
                использовать в личных целях в соответствии с функционалом Сервиса Мобильное приложение «МТС Деньги».
            </p>
            <p>
                9.9. Принимая условия настоящего Соглашения о пользовании сервисом «Кошелек МТС Деньги» Пользователь выражает полное и
                безоговорочное согласие на получение рекламной и иной информации от МТС и(или) Партнеров МТС, которые участвуют в оказании
                Сервиса Пользователю по сетям электросвязи и на прямые контакты с Пользователем с целью продвижения товаров, работ, услуг
                МТС и(или) Партнеров МТС, включая направление рекламной и иной информации по абонентским номерам и(или) электронной почте,
                которые стали доступны МТС и(или) Партнерам МТС в силу оказания Сервиса в соответствии с настоящим Соглашением. Пользователь
                вправе в любое время отозвать свое согласие, предоставленное в соответствии с настоящим пунктом, предоставив письменное
                заявление в салонах-магазинах МТС (перечень на сайте{' '}
                <Link href="http://www.mts.ru/" target="_blank" rel="nofollow">
                    www.mts.ru
                </Link>
                ) c документом, удостоверяющим личность Пользователя.
            </p>
            <p>
                <strong>10. </strong>
                <strong>Прекращение действия настоящего Соглашения: </strong>
            </p>
            <p>
                10.1. Если иное не предусмотрено настоящим Соглашением, Соглашение действует до тех пор, пока одна из сторон не заявит о
                своем намерении в одностороннем внесудебном порядке отказаться от настоящего Соглашения.
            </p>
            <p>
                10.2. Оператор вправе без предупреждения приостановить использование Пользователем Сервиса, в т.ч. путем приостановления
                возможности пользования Персональным кабинетом Сервиса в случаях нарушения Пользователем условий данного Соглашения.
            </p>
            <p>
                <strong>11. </strong>
                <strong>К настоящему Соглашению прилагаются и являются его неотъемлемой частью: </strong>
            </p>
            <p>
                -{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_mts_pay.pdf" target="_blank" rel="nofollow">
                    Условия по предоставлению сервиса «Кошелек МТС Деньги» при оплате с помощью денежных средств на лицевом счете
                </Link>{' '}
                (для Абонентов МТС – физических лиц);
            </p>
            <p>
                -{' '}
                <Link
                    href="http://static.mts.ru/uploadmsk/contents/1655/usloviya_predostavl_servisa_s_bk.pdf"
                    target="_blank"
                    rel="nofollow"
                >
                    Условия предоставления сервиса «Кошелек МТС Деньги» с использованием денежных средств на банковской карте
                </Link>
                ;
            </p>
            <p>
                -{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/usl_use_eletron_money.pdf" target="_blank" rel="nofollow">
                    Условия предоставления сервиса «Кошелек МТС Деньги» с использованием электронных денежных средств ПАО «МТС-Банк»
                </Link>
                ;
            </p>
            <p>
                -{' '}
                <Link href="http://static.mts.ru/uploadmsk/contents/1655/oferta_all_esp.pdf" target="_blank" rel="nofollow">
                    Общие условия осуществления перевода электронных денежных средств и обслуживания электронных средств платежа ПАО
                    «МТС-Банк»
                </Link>
                .
            </p>
            <p>ПАО «МТС»</p>
            <p>109147, г. Москва, ул. Марксистская, д. 4.</p>
            <p>Тел. 8 800 250 08 90</p>
            <div>
                <br />
                <hr />
                <div>
                    <p>
                        <sup>1</sup> Здесь и далее SMS = СМС – короткие текстовые сообщения
                    </p>
                </div>
                <div>
                    <p>
                        <sup>2</sup> USSD = Unstructured Supplementary Service Data — стандартный сервис в сетях{' '}
                        <Link href="https://ru.wikipedia.org/wiki/GSM" title="GSM" target="_blank" rel="nofollow">
                            GSM
                        </Link>
                        , позволяющий организовать интерактивное взаимодействие между{' '}
                        <Link
                            href="https://ru.wikipedia.org/wiki/%D0%90%D0%B1%D0%BE%D0%BD%D0%B5%D0%BD%D1%82"
                            title="Абонент"
                            target="_blank"
                            rel="nofollow"
                        >
                            абонентом
                        </Link>{' '}
                        сети и сервисным приложением в режиме передачи коротких сообщений)
                    </p>
                </div>
                <div>
                    <p>
                        <sup>3</sup> Информация о том, необходимо ли для проведения конкретной Операции и Платежа пройти процедуру
                        упрощенной идентификации или идентификации, появляется при пользовании конкретными инструментами, указанными в п.
                        2.2. настоящего Соглашения. Следите за подсказками программного обеспечения при пользовании Сервисом.
                    </p>
                </div>
            </div>
        </div>
    </>
);
