import { createGlobalStyle } from 'styled-components';
import { palette, breakpoints } from 'lib/theme';
import { normalize } from 'assets/normalize.css';
import MTSSansRegularWEot from './fonts/MTSSans-Regular__W.eot';
import MTSSansRegularWWoff2 from './fonts/MTSSans-Regular__W.woff2';
import MTSSansRegularWWoff from './fonts/MTSSans-Regular__W.woff';
import MTSSansRegularWTtf from './fonts/MTSSans-Regular__W.ttf';
import MTSSansRegularWSvg from './fonts/MTSSans-Regular__W.svg';
import MTSSansMediumWEot from './fonts/MTSSans-Medium__W.eot';
import MTSSansMediumWWoff2 from './fonts/MTSSans-Medium__W.woff2';
import MTSSansMediumWWoff from './fonts/MTSSans-Medium__W.woff';
import MTSSansMediumWTtf from './fonts/MTSSans-Medium__W.ttf';
import MTSSansMediumWSvg from './fonts/MTSSans-Medium__W.svg';
import MTSSansBoldWEot from './fonts/MTSSans-Bold__W.eot';
import MTSSansBoldWWoff2 from './fonts/MTSSans-Bold__W.woff2';
import MTSSansBoldWWoff from './fonts/MTSSans-Bold__W.woff';
import MTSSansBoldWTtf from './fonts/MTSSans-Bold__W.ttf';
import MTSSansBoldWSvg from './fonts/MTSSans-Bold__W.svg';

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  
  @font-face {
    font-family: 'MTSSans';
    src: local('MTSSans'), local('MTSSans'),
    url(${MTSSansRegularWEot}) format('embedded-opentype'),
    url(${MTSSansRegularWWoff2}) format('woff2'),
    url(${MTSSansRegularWWoff}) format('woff'),
    url(${MTSSansRegularWTtf}) format('truetype'),
    url(${MTSSansRegularWSvg}) format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'MTSSans';
    src: local('MTSSans'), local('MTSSans'),
    url(${MTSSansMediumWEot}) format('embedded-opentype'),
    url(${MTSSansMediumWWoff2}) format('woff2'),
    url(${MTSSansMediumWWoff}) format('woff'),
    url(${MTSSansMediumWTtf}) format('truetype'),
    url(${MTSSansMediumWSvg}) format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MTSSans';
    src: local('MTSSans'), local('MTSSans'),
    url(${MTSSansBoldWEot}) format('embedded-opentype'),
    url(${MTSSansBoldWWoff2}) format('woff2'),
    url(${MTSSansBoldWWoff}) format('woff'),
    url(${MTSSansBoldWTtf}) format('truetype'),
    url(${MTSSansBoldWSvg}) format('svg');
    font-weight: 700;
    font-style: normal;
  }

  html, body {
    font-family: 'MTSSans', Corpid, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: 'tnum' on, 'lnum' on;
    font-size: 16px;
    line-height: 24px;
    height: 100%;
    padding: 0;
    margin: 0;
    color: ${palette.deepBlue};
    background-color: ${palette.white};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    scroll-behavior: smooth;

    @media (min-width: ${breakpoints.tablet}) {
      background-color: ${palette.extraLightGray};
    }
  }

  .scroll-lock {  
    overflow:hidden;
  }

  * {
    box-sizing: border-box;
  }

  #app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    position: relative;

    @media (min-width: ${breakpoints.tablet}) {
      background-color: ${palette.extraLightGray};
    }
  }
  
  .toastProvider {
    padding: 16px;
  }
`;
