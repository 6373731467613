import { isQuantityWebauthn, isWebauthnData, QuantityWebauthn, SetQuantityWebauthn, WebauthnData } from 'types/webauthn';
import isMobile from 'ismobilejs';

const DISPLAY_FREQUENCY = 86400000 as const; // 1 day in ms частота показа предложения подлкючить webauthn
const EXPIRED_PERIOD = 2592000000 as const; // 30 days in ms период, после которого вновь показать предложение подключить webauthn

export const MAX_ATTEMPT_NUMBER = 3 as const;
export const PHONE_PATTERN = /^7\d{10}$/;

export class WebauthnCheck {
    // @ts-ignore
    private quantityWebauthn: QuantityWebauthn;

    private userName: string;

    private webauthn: WebauthnData | undefined;

    constructor() {
        this.initQuantityWebauthn();
        this.initWebauthn();

        const phone = sessionStorage.getItem('login');

        this.userName = phone && this.isValidPhone(phone) ? phone : '';
    }

    public checkWebauthnAvailability = () =>
        new Promise<boolean>((resolve) => {
            const deviceType = isMobile(navigator.userAgent);

            if (window.PublicKeyCredential && window.navigator.credentials && (deviceType?.phone || deviceType?.tablet)) {
                window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
                    .then((available) => {
                        resolve(available);
                    })
                    .catch(() => resolve(false));
            } else {
                resolve(false);
            }
        });

    private isValidPhone = (phone: string) => {
        return phone.match(PHONE_PATTERN);
    };

    public getUserName = () => {
        return this.userName;
    };

    private initQuantityWebauthn = () => {
        const defaultQuantityWebauthn = { attemptNumber: 0, date: 0, expires: Date.now() + EXPIRED_PERIOD };
        const quantityWebauthn = window.localStorage.getItem('quantity_webauthn');

        try {
            const parsedQuantityWebauthn: Partial<QuantityWebauthn> = JSON.parse(quantityWebauthn || '{}');

            this.quantityWebauthn = isQuantityWebauthn(parsedQuantityWebauthn) ? parsedQuantityWebauthn : defaultQuantityWebauthn;
        } catch (error) {
            this.quantityWebauthn = defaultQuantityWebauthn;
        }
    };

    private initWebauthn = () => {
        const webAuthn = window.localStorage.getItem('webauthn');

        try {
            const parsedWebauthn: Partial<WebauthnData> = JSON.parse(webAuthn || '{}');
            this.webauthn = isWebauthnData(parsedWebauthn) ? parsedWebauthn : undefined;
        } catch (error) {
            this.webauthn = undefined;
        }
    };

    public isWebauthnEnabled = () => {
        return Boolean(this.webauthn?.enabled);
    };

    public setWebauthn = (value: WebauthnData) => {
        this.webauthn = value;

        window.localStorage.setItem('webauthn', JSON.stringify(this.webauthn));
    };

    public isNeedWebauthnEnable = () => {
        const currentTime = Date.now();
        const { attemptNumber, date, expires } = this.quantityWebauthn;

        return (attemptNumber < MAX_ATTEMPT_NUMBER && date + DISPLAY_FREQUENCY < currentTime) || expires < currentTime;
    };

    public getQuantityWebauthn = (): QuantityWebauthn => this.quantityWebauthn;

    public setQuantityWebauthn: SetQuantityWebauthn = (params) => {
        const currentTime = Date.now();
        const quantityWebauthn = {
            date: currentTime,
            expires: currentTime + EXPIRED_PERIOD,
        };

        this.quantityWebauthn = { ...quantityWebauthn, ...(params || {}) };

        window.localStorage.setItem('quantity_webauthn', JSON.stringify(this.quantityWebauthn));
    };

    public resetWebauthn = () => {
        window.localStorage.removeItem('webauthn');
    };
}
