import { getNumericValue } from './getNumericValue';

export enum Identity {
    id = 'id',
    card = 'card',
}

export const isIdentityType = (type: any): type is Identity => type in Identity;

const identityTypes = {
    [Identity.id]: {
        spaces: [2, 5],
        length: 12,
    },
    [Identity.card]: {
        spaces: [4, 9, 14, 19],
        length: 23,
    },
};

const formatValue = (result: string, symbol: string, spaces: number[]) => {
    const isAddSpace = spaces.includes(result.length);
    if (isAddSpace) {
        return [result, ' ', symbol].join('');
    }
    return [result, symbol].join('');
};

export const formatIdentityInput = (value: string, type: Identity) => {
    if (type === Identity.id) {
        return value.replace(/[^a-zA-Z\d]/g, '').toUpperCase();
    }

    const numericValue = getNumericValue(value);
    const formattedValue = numericValue
        .split('')
        .reduce((result: string, symbol: string) => formatValue(result, symbol, identityTypes[type].spaces), '')
        .slice(0, identityTypes[type].length);

    return formattedValue;
};
