import React, { ReactNode, useState, useEffect, useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import styled from 'styled-components';
import { Content } from 'components/common/Content';
import { Button } from 'components/common/Button';
import { palette, breakpoints } from 'lib/theme';
import { Text } from 'components/common/Text';
import { ReactComponent as CloseIcon } from 'assets/svg/iconClear.svg';
import { stepParams } from 'lib/stepParams';
import { isWebview } from 'utils/isWebview';
import { showScreenPolicyGTM } from 'utils/GTM';

interface Props {
    children: ReactNode;
    closePolicy: (e: React.MouseEvent) => void;
    login: string;
    type: 'policy' | 'offer';
    acceptPolicy?: (e: React.MouseEvent) => void;
}

export const Policy = ({ closePolicy, acceptPolicy, type, children, login }: Props) => {
    const [disabled, setDisabled] = useState(true);
    const detectBlock = useRef<HTMLDivElement | null>(null);

    const params = stepParams[type];

    useEffect(() => {
        const detectBlockRef = detectBlock.current;

        const callback = (entries: IntersectionObserverEntry[]) => {
            if (!entries[0].isIntersecting) {
                return;
            }
            setDisabled(false);
        };
        const observer = new IntersectionObserver(callback, { root: null, threshold: 1 });

        if (detectBlockRef) {
            observer.observe(detectBlockRef);
        }

        return () => {
            if (!detectBlockRef) {
                return;
            }
            observer.unobserve(detectBlockRef);
        };
    }, []);

    useEffect(() => {
        showScreenPolicyGTM({ login });
    }, [login]);

    return (
        <FocusTrap>
            <PolicyStyled>
                <PolicyOverLay>
                    <PolicyContentStyled type={type}>
                        {children}
                        <Content heading={params.heading}>
                            <CloseButton onClick={closePolicy} tabIndex={0}>
                                <CloseIcon />
                            </CloseButton>
                            <PolicyScrollBlock>
                                {'text' in params && <PolicyTextStyled>{params.text}</PolicyTextStyled>}
                                <div ref={detectBlock} />
                            </PolicyScrollBlock>
                            {type === 'policy' && 'buttonText' in params && (
                                <ButtonGroup>
                                    <Button
                                        type="button"
                                        onClick={acceptPolicy}
                                        styleType={disabled ? 'disabled' : 'primary'}
                                        disabled={disabled}
                                    >
                                        {params.buttonText}
                                    </Button>
                                </ButtonGroup>
                            )}
                        </Content>
                    </PolicyContentStyled>
                </PolicyOverLay>
            </PolicyStyled>
        </FocusTrap>
    );
};

const PolicyContentStyled = styled.div<{ type?: string }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 1.5rem;
    background-color: ${palette.white};

    ${({ type }) =>
        type === 'policy' &&
        `
    h1 {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  `}

    @media (min-width: ${breakpoints.mobileL}) {
        padding: 2rem;
    }

    @media (min-width: ${breakpoints.tablet}) {
        width: 40.75rem;
        border-radius: 0.5rem;
        padding: 3rem;
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08), 0 0.375rem 0.75rem rgba(0, 0, 0, 0.08);

        h1 {
            margin-top: 2rem;
        }
    }

    @media (min-width: ${breakpoints.desktop}) {
        max-height: ${isWebview() ? '37rem' : '100%'};
    }
`;

const PolicyScrollBlock = styled.div`
    overflow-y: auto;
    position: relative;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-top: 0.75rem;

    &:after {
        content: '';
        height: 13rem;
        display: block;

        @media (min-width: ${breakpoints.tablet}) {
            height: 3rem;
        }
    }
`;

const PolicyTextStyled = styled(Text)`
    margin: 0 auto;
`;

const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background-color: transparent !important;
    color: ${palette.midGray};
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none !important;
    }
    &.using-tab:focus {
        outline: none !important;
        border-radius: 0.125rem;
        box-shadow: 0 0 0 3px ${palette.lightMidGray};
    }

    @media (min-width: ${breakpoints.tablet}) {
        top: 3rem;
        right: 3rem;
    }
`;

const ButtonGroup = styled.div`
    position: fixed;
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255), #ffffff);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    width: 100%;

    button {
        margin-top: 0;
    }

    @media (min-width: ${breakpoints.mobileL}) {
        padding-bottom: 1rem;

        button {
            width: 17rem;
        }
    }

    @media (min-width: ${breakpoints.tablet}) {
        width: 40.75rem;
        margin: 0 auto;
    }
`;

const PolicyStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
`;

const PolicyOverLay = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.tablet}) {
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
    }
`;
