import { getAdditionalQueryParams } from './getAdditionalQueryParams';
import { isWebview } from './isWebview';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Window {
        HandlerForChoosingAnotherNumber?: { onButtonClick: () => unknown };
        webkit?: { messageHandlers: { HandlerForChoosingAnotherNumber: { postMessage: (message: { command: string }) => unknown } } };
    }
}

const { fromAccountList } = getAdditionalQueryParams();

export function sendEventToApp() {
    if (isWebview() && fromAccountList) {
        if (window?.HandlerForChoosingAnotherNumber) {
            window.HandlerForChoosingAnotherNumber.onButtonClick();
        }
        if (window?.webkit?.messageHandlers?.HandlerForChoosingAnotherNumber) {
            window.webkit.messageHandlers.HandlerForChoosingAnotherNumber.postMessage({ command: 'backButtonClicked' });
        }
    }
}
