import styled from 'styled-components';
import { palette } from 'lib/theme';

export const Label = styled.label`
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: nowrap;
    display: block;
    margin-bottom: 0.5rem;
    color: ${palette.midGray};
`;
