import { Button, Heading, theme, useToast } from '@mtsbank/ui-kit';
import { ReactComponent as FaceId } from 'assets/svg/faceId.svg';
import { breakpoints, palette } from 'lib/theme';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { StateContext } from 'state';
import styled from 'styled-components';
import { LocationState, ToastType, WebauthnError } from 'types/webauthn';
import { api } from 'utils/api';
import {
    AuthTypeGTM,
    clickLoginWithBioGTM,
    clickLoginWithPhoneGTM,
    doAuthGTM,
    showScreenAuthFormGTM,
    showToastErrorLoginWithBioGTM,
} from 'utils/GTM';
import { Webauthn } from 'utils/webauthn/Webauthn';
import { WebauthnCheck } from 'utils/webauthn/WebauthnCheck';
import { WebauthnContext } from './context';
import { ToastButtons } from './ToastButtons';

export const AuthorizationForm = () => {
    const history = useHistory<LocationState>();
    const { toast } = useToast();
    const { setBackground } = useContext(WebauthnContext);
    const { login } = useContext(StateContext);

    const webauthn = useMemo(() => new Webauthn(), []);

    const doAuthorize = useCallback(() => {
        setBackground(true);
        webauthn
            .authorize()
            .then((assertion) =>
                api
                    .login(assertion)
                    .then((response) => {
                        if (response.redirect_url) {
                            doAuthGTM(login, { authTypeGTM: AuthTypeGTM.BIO });
                            window.gib.setAuthStatus(true);
                            window.location.replace(response.redirect_url);

                            return;
                        }

                        if (response.next_step === 'check_additional_data') {
                            history.push('/auth', { stepType: 'confirmation', webauthn: true });

                            return;
                        }

                        throw new Error();
                    })
                    .catch((error: Error) => {
                        const errorMessage = JSON.parse(error.message);
                        const isCredentialsNotExist = errorMessage.error === WebauthnError.CREDENTIALS_NOT_EXIST;

                        if (isCredentialsNotExist) {
                            new WebauthnCheck().resetWebauthn();

                            toast(ToastType.ERROR, 'Ошибка', 'Не\u00A0удалось войти по\u00A0данным биометрии', {
                                buttons: () => (
                                    <ToastButtons
                                        type={ToastType.ERROR}
                                        leftButtonTitle="Войти по&nbsp;номеру телефона"
                                        onContinueCLick={() => history.push('/auth')}
                                    />
                                ),
                            });
                            showToastErrorLoginWithBioGTM({ login });

                            return;
                        }

                        throw error;
                    })
            )
            .catch((error) => {
                if (error.message === WebauthnError.AUTHENTICATOR_CANCELED) {
                    return;
                }

                toast(ToastType.ERROR, 'Ошибка', 'Не\u00A0удалось войти по\u00A0данным биометрии', {
                    withClose: true,
                });
                showToastErrorLoginWithBioGTM({ login });
            })
            .finally(() => {
                setBackground(false);
            });
    }, [history, login, setBackground, toast, webauthn]);

    const handleAuthClick = useCallback(() => {
        clickLoginWithBioGTM({ login });
        doAuthorize();
    }, [doAuthorize, login]);

    const handleClickAuthByPhone = useCallback(() => {
        clickLoginWithPhoneGTM({ login });
        history.push('/auth', { webauthn: true });
    }, [history, login]);

    useEffect(() => {
        if (history.location.state?.webauthn) {
            history.replace({ state: { webauthn: false } });

            doAuthorize();
        } else {
            showScreenAuthFormGTM();
        }
    }, [doAuthorize, history, history.location, history.location.state?.webauthn, setBackground]);

    return (
        <OuterWrapper>
            <InnerWrapper>
                <Wrapper>
                    <ImageWrapper>
                        <img src="https://staticpayment.ssl.mts.ru/img/logoMtsBank.png" alt="logo" width={134} height={22} />
                    </ImageWrapper>
                    <Heading noMargin h={3}>
                        Вход в&nbsp;личный кабинет
                    </Heading>
                    <ButtonsWrapper>
                        <Button variant="primary" size="lg" startIcon={<FaceIdIcon />} onClick={handleAuthClick}>
                            По&nbsp;биометрии
                        </Button>
                        <Button variant="secondary" size="lg" onClick={handleClickAuthByPhone}>
                            По&nbsp;номеру телефона
                        </Button>
                    </ButtonsWrapper>
                </Wrapper>
            </InnerWrapper>
        </OuterWrapper>
    );
};

const ImageWrapper = styled.div`
    position: absolute;
    top: ${theme.spacings.xs}px;

    @media (min-width: ${breakpoints.tablet}) {
        position: initial;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacings.md}px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    > :nth-child(n) {
        padding: 0;
    }
`;

const FaceIdIcon = styled(FaceId)`
    margin-right: 4px;
`;

const OuterWrapper = styled.div`
    width: 100%;
    margin: auto;

    @media (min-width: ${breakpoints.tablet}) {
        width: auto;
        margin-top: 72px;
    }
`;

const InnerWrapper = styled.div`
    background: ${palette.white};
    padding: ${theme.spacings.xs}px;

    width: 100%;
    border-radius: 24px;

    @media (min-width: ${breakpoints.tablet}) {
        padding: 44px 48px;
        width: 368px;
    }
`;
