import React, { HTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import { Label } from 'components/common/Label';
import { inputStyles, palette } from 'lib/theme';
import { PhoneInput } from '@mtsbank/ui-kit';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

type Type = keyof typeof inputStyles;

interface Props {
    id: Type;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick: () => void;
    type: string;
    autoComplete?: string;
    disabled?: boolean;
    errorMessage?: string;
    inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
    isError?: boolean;
    label?: string;
    placeholder?: string;
    value?: string;
}

export const FieldWithMask = ({
    id,
    label,
    disabled,
    onChange,
    onClick,
    errorMessage,
    isError,
    placeholder,
    inputMode = 'numeric',
    value = '',
    type = 'tel',
    name,
    autoComplete = 'off',
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {label && <Label htmlFor={id}>{label}</Label>}
            <FieldStyled ref={inputRef}>
                <PhoneInput
                    isClearable
                    name={name}
                    id={id}
                    value={formatPhoneNumber(value)}
                    onChange={onChange}
                    hasError={isError}
                    disabled={disabled}
                    placeholder={placeholder}
                    onClear={onClick}
                    type={type}
                    inputMode={inputMode}
                    autoComplete={autoComplete}
                />
            </FieldStyled>
            {isError && <ErrorMessage data-testid="error">{errorMessage}</ErrorMessage>}
        </>
    );
};

const FieldStyled = styled.div`
    position: relative;
    margin-top: 10px;
`;

const ErrorMessage = styled.div`
    font-size: 0.875rem;
    color: ${palette.orange};
    line-height: 1.25rem;
    margin-top: 0.25rem;
`;
