import {
    ActionGroupGTM,
    EventGTM,
    EventActionGTM,
    EventCategoryGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
    EventLabelsGTM,
    ErrorsParamsGTM,
} from 'utils/GTM/types';
import { baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import { isValidNumberString } from 'utils/isValidNumberString';

const baseConfirmationOtpParams = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    userAuth: UserAuthGTM.UNAUTHENTICATED,
    screenName: ScreenNamesGTM.LOGIN,
    pageType: PageTypeGTM.SSO_AUTH,
};

/** Успешное подтверждение через смс
 * auth_form-form_confirmed-kod_iz_sms
 * */
export const successConfirmationOtpGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseConfirmationOtpParams,
        eventAction: EventActionGTM.FORM_CONFIRMED,
        eventLabel: EventLabelsGTM.CODE_FROM_SMS,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });

/** Ошибка ввод кода из смс
 * auth_form-error_show-kod_vveden_nekorrektno
 * */
export const errorConfirmationOtpGTM = ({ description, messageCode, login, eventValue }: ErrorsParamsGTM) =>
    sendGmtEvent({
        ...baseConfirmationOtpParams,
        eventAction: EventActionGTM.ERROR_SHOW,
        eventLabel: EventLabelsGTM.WRONG_CODE,
        eventValue: isValidNumberString(eventValue) ? String(eventValue) : null,
        eventContent: messageCode || null, // текст ошибки
        filterName: description || null, // id - код ошибки
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });

/** Ввод кода из смс
 * Событие срабатывает каждый раз при введении кода из смс (Поле заполнено, прошло валидацию и фокус с поля убран)
 * auth_form-form_add-kod_iz_sms
 * */
export const submitOtpGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseConfirmationOtpParams,
        eventAction: EventActionGTM.FORM_ADD,
        eventLabel: EventLabelsGTM.CODE_FROM_SMS,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });

/** Ввод кода из смс
 * Клик на кнопку "Отправить код повторно"
 * auth_form-button_click-otpravit_kod_povtorno
 * */
export const resendOtpGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseConfirmationOtpParams,
        eventAction: EventActionGTM.BUTTON_CLICK,
        eventLabel: EventLabelsGTM.RESEND_OTP,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });

/** Показ экрана OTP
 * scrn
 * */
export const showScreenOtpGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.CODE_FROM_SMS,
        screenName: ScreenNamesGTM.LOGIN,
        ph: getPh(login),
    });
