import React from 'react';
import { RestrictedType } from 'lib/restrictedParams';
import { StepType } from 'components/steps/Steps';
import { Identity } from 'utils/formatIdentityInput';
import { ModalProps } from 'components/common/Modal';
import { ScreenNamesGTM } from './utils/GTM';
import { ResponseError } from './utils/api';

export interface BlockAttemptsTime {
    login: string;
    time: string;
}

export interface StateContextType {
    blockAttempts: BlockAttemptsTime;
    closeModal: () => void;
    displayPolicyType: 'offer' | 'policy' | null;
    getErrorModal: (fn: () => void, error: ResponseError, screenName: ScreenNamesGTM) => void;
    getIsBlockedTime: (time: string, isCurrentLogin: boolean) => boolean;
    identityAttempts: number;
    login: string;
    openOffer: () => void;
    openPolicy: () => void;
    restrictedType: RestrictedType;
    setBlockAttempts: (blockAttempts: BlockAttemptsTime) => void;
    setDisplayStep: (step: StepType) => void;
    setIdentityAttempts: (attempts: number) => void;
    setIdentityType: (type: Identity) => void;
    setLoading: (state: boolean) => void;
    setLogin: (login: string) => void;
    setModalProps: (props: ModalProps) => void;
    setRestrictedType: (type: RestrictedType) => void;
    setUsername: (username: string) => void;
    showOTP: (phone?: string) => Promise<void>;
    toggleModal: () => void;
    toggleScrollLock: () => void;
    updateClient: (step?: StepType, type?: RestrictedType) => (e: React.MouseEvent<HTMLElement>) => void;
    username: string;
}

export const StateContext = React.createContext<StateContextType>({} as StateContextType);
