import styled from 'styled-components';
import { palette } from 'lib/theme';

export const Error = styled.p`
    padding: 0 0 0.125rem;
    margin: 0.25rem 0 0;
    width: 100%;
    max-width: 100%;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${palette.orange};
`;
