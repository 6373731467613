import React, { useRef, useState, useEffect, HTMLAttributes, useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { inputStyles } from 'lib/theme';
import { ClearButton } from 'components/common/ClearButton';
import { Input } from 'components/common/Input';
import { Label } from 'components/common/Label';
import { PhonePrefix } from 'components/common/PhonePrefix';

interface Props {
    id: keyof typeof inputStyles;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: () => void;
    register: UseFormMethods['register'];
    autoComplete?: string;
    disabled?: boolean;
    errorMessage?: string;
    inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
    isError?: boolean;
    isFocused?: boolean;
    label?: string | null;
    maxLength?: number;
    minLength?: number;
    onBlurGTM?: () => void;
    placeholder?: string;
    type?: string;
}

export const Field = ({
    id,
    label = null,
    type = 'tel',
    placeholder = '',
    disabled = false,
    register,
    minLength = 0,
    maxLength = 0,
    autoComplete = 'off',
    inputMode = 'numeric',
    errorMessage = '',
    isError = false,
    isFocused = false,
    onChange,
    onClick,
    onBlurGTM,
}: Props) => {
    const [displayButton, setDisplayButton] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [isFocused]);

    const onFocus = useCallback(() => {
        setDisplayButton(true);
    }, []);

    const onBlur = useCallback(() => {
        if (onBlurGTM) {
            onBlurGTM();
        }

        setDisplayButton(false);
    }, [onBlurGTM]);

    const onClearClick = useCallback(() => {
        onClick();

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [onClick]);

    const handleRef = useCallback(
        (ref: HTMLInputElement) => {
            register(ref, {
                required: true,
                minLength: {
                    value: minLength,
                    message: errorMessage,
                },
            });

            inputRef.current = ref;
        },

        [register, minLength, errorMessage]
    );

    return (
        <>
            {label && <Label htmlFor={id}>{label}</Label>}
            <FieldStyled>
                {id === 'phone' && <PhonePrefix />}
                <InputStyled
                    id={id}
                    name={id}
                    type={type}
                    inputMode={inputMode}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    disabled={disabled}
                    maxLength={maxLength}
                    ref={handleRef}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    isError={isError}
                />
                <ClearButton onClick={onClearClick} isShown={displayButton} />
            </FieldStyled>
        </>
    );
};

const FieldStyled = styled.div`
    position: relative;
`;

const InputStyled = styled(Input)<{ id: keyof typeof inputStyles }>`
    ${({ id }) => inputStyles[id]}
`;
