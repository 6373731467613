import { api } from 'utils/api';
import { base64 } from 'utils/base64';
import { PublicKeyCredentialCreate, PublicKeyCredentialGet, WebauthnError } from 'types/webauthn';
import { WebauthnCheck } from './WebauthnCheck';

export class Webauthn {
    private webauthnCheck: WebauthnCheck | undefined;

    private createCredentials = (challenge: string) =>
        navigator.credentials
            .create({ publicKey: this.getPublicKeyCreationOptions(challenge) })
            .then((response) => {
                if (!response) {
                    return Promise.reject(response);
                }

                const credential = response as PublicKeyCredentialCreate;

                const credentialData = {
                    id: credential.id,
                    rawId: base64.urlEncode(credential.rawId),
                    response: {
                        clientDataJSON: base64.urlEncode(credential.response.clientDataJSON), // todo внернуть после доработок бэка
                        attestationObject: base64.urlEncode(credential.response.attestationObject),
                    },
                    type: credential.type,
                };

                return api.verifyCredentials(credentialData).then((result) => (result ? Promise.resolve(result) : Promise.reject(result)));
            })
            .catch((error) => Promise.reject(error));

    private getCredentials = (challenge: string) =>
        navigator.credentials
            .get({ publicKey: this.getPublicKeyRequestOptions(challenge) })
            .then((response) => {
                if (!response) {
                    return Promise.reject(response);
                }

                const assertionData = response as PublicKeyCredentialGet;

                return {
                    id: assertionData.id,
                    rawId: base64.urlEncode(assertionData.rawId),
                    response: {
                        authenticatorData: base64.urlEncode(assertionData.response.authenticatorData),
                        clientDataJSON: base64.urlEncode(assertionData.response.clientDataJSON),
                        signature: base64.urlEncode(assertionData.response.signature),
                    },
                    type: 'public-key',
                };
            })
            .catch(() => {
                throw new Error(WebauthnError.AUTHENTICATOR_CANCELED);
            });

    private getPublicKeyCreationOptions = (challenge: string): PublicKeyCredentialCreationOptions | undefined => {
        if (!this.webauthnCheck) {
            return undefined;
        }

        const userName = this.webauthnCheck.getUserName();
        const randomString = base64.encode((Math.random() * Date.now()).toFixed());

        return {
            challenge: Uint8Array.from(challenge, (c) => c.charCodeAt(0)),
            rp: {
                name: 'Payment MTS',
                id: 'mtsbank.ru',
                // id: 'localhost', // todo id должен содержать домен, с которого выполняется подключения webauthn (для локальной разрботки это localhost)
            },
            user: {
                id: Uint8Array.from(randomString, (c) => c.charCodeAt(0)),
                name: userName,
                displayName: userName,
            },
            pubKeyCredParams: [
                { alg: -7, type: 'public-key' },
                { alg: -257, type: 'public-key' },
            ],
            authenticatorSelection: {
                authenticatorAttachment: 'platform',
                residentKey: 'preferred',
            },
            timeout: 60000,
            attestation: 'direct',
        };
    };

    private getPublicKeyRequestOptions = (challenge: string): PublicKeyCredentialRequestOptions => ({
        challenge: Uint8Array.from(challenge, (c) => c.charCodeAt(0)),
        allowCredentials: [],
        timeout: 60000,
        // rpId: 'localhost', // todo для dev-разработки на localhost
        rpId: 'mtsbank.ru',
        userVerification: 'preferred',
    });

    public setWebauthnCheck = (webauthnCheck: WebauthnCheck) => {
        this.webauthnCheck = webauthnCheck;
    };

    public enable = () =>
        api
            .getChallenge()
            .then(this.createCredentials)
            .catch((error) => Promise.reject(error));

    public authorize = () =>
        api
            .getChallenge()
            .then(this.getCredentials)
            .catch((error) => Promise.reject(error));
}
