import isMobile from 'ismobilejs';

const rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
    'Android.*Version/[0-9].[0-9]',
    // Also, we should save the wv detected for Lollipop
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    'Android.*wv',
    // old chrome android webview agent
    'Linux; U; Android',
];

const webviewRegExp = new RegExp(`(${rules.join('|')})`, 'ig');

function isUaWebview(ua: string) {
    return !!ua.match(webviewRegExp);
}

const { navigator } = window;
const { userAgent } = navigator;
const isIos = isMobile(navigator).apple.device;
const isSafari = /safari/.test(userAgent.toLowerCase());
export const isIosWebview = isIos && !isSafari;

export const isWebview = () => {
    // по всей видимости библиотека is-ua-webview также проверяет webview ios и вероятно её одной достаточно, необходимо проверить дополнительно
    const isOtherWebview = isUaWebview(userAgent);
    return isIosWebview || isOtherWebview;
};
