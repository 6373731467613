import ReactDOM from 'react-dom';
import React, { ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import { Button } from 'components/common/Button';
import { ClearButton } from 'components/common/ClearButton';
import FocusTrap from 'focus-trap-react';
import { breakpoints, palette } from 'lib/theme';
import styled from 'styled-components';
import { Text } from 'components/common/Text';
import { StateContext } from 'state';
import { getScrollWidth } from 'utils/getScrollWidth';
import { isWebview } from 'utils/isWebview';
import { gtmPage, onGtmClick } from 'utils/GTM';

export interface ModalProps {
    heading: string;
    message: string;
    onPrimaryClick: ((e: React.MouseEvent<HTMLElement>) => void) | 'callToBank';
    primaryButton: string;
    errorId?: string;
    icon?: ReactNode;
    onSecondaryClick?: ((e: React.MouseEvent<HTMLElement>) => void) | 'callToBank';
    secondaryButton?: string;
}
const CallToBankLink = ({ primaryButton, secondaryButton }: { primaryButton: string; secondaryButton: string | undefined }) => {
    if (isWebview()) {
        return (
            <ModalButton
                as="a"
                href="tel:+78002500520"
                isSingle={!secondaryButton?.length}
                className="btn-modal__first"
                styleType="primary"
            >
                {primaryButton}
            </ModalButton>
        );
    }
    return (
        <ModalButton
            as="a"
            href="https://www.mtsbank.ru/o-banke/kontakti/"
            target="_blank"
            rel="nofollow norefferrer noopener"
            isSingle={!secondaryButton?.length}
            className="btn-modal__first"
            styleType="primary"
        >
            {primaryButton}
        </ModalButton>
    );
};

export const Modal = ({
    heading,
    message,
    primaryButton,
    secondaryButton,
    icon,
    onPrimaryClick,
    onSecondaryClick,
    errorId,
}: ModalProps) => {
    const { closeModal, login } = useContext(StateContext);

    useEffect(() => {
        const scrollWidth = getScrollWidth();
        document.body.style.paddingRight = `${scrollWidth}px`;

        return () => {
            document.body.removeAttribute('style');
        };
    }, []);

    const contentRef = useRef<HTMLDivElement>(null);

    const onClickOutside = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (contentRef?.current?.contains(e.target as Node)) {
                return;
            }
            closeModal();
        },
        [closeModal]
    );

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            e.preventDefault();

            if (e.keyCode === 27) {
                closeModal();
            }
        },
        [closeModal]
    );

    useEffect(() => {
        gtmPage({ heading, message, login, errorId });
    }, [heading, message, login, errorId]);

    const onButtonsCLick = useCallback(
        (e: any) => {
            const { innerText } = e.target;
            if (!innerText) {
                return;
            }
            onGtmClick({ errorId, message, heading, login, text: innerText });
        },
        [errorId, heading, login, message]
    );

    const modal = (
        <FocusTrap>
            <aside tabIndex={-1} aria-modal="true" role="dialog">
                <ModalOverlay />
                <ModalContentWrapper onClick={onClickOutside} onKeyDown={onKeyDown}>
                    <ModalContent ref={contentRef}>
                        <ClearButton onClick={closeModal} isShown inModal aria-label="Close Modal" />
                        {icon}
                        <h2>{heading}</h2>
                        {message.length > 0 && (
                            <ModalTextContainer>
                                <ModalText>{message}</ModalText>
                            </ModalTextContainer>
                        )}
                        <ModalButtonGroup onClick={onButtonsCLick}>
                            {onPrimaryClick === 'callToBank' ? (
                                <CallToBankLink primaryButton={primaryButton} secondaryButton={secondaryButton} />
                            ) : (
                                <ModalButton
                                    isSingle={!secondaryButton?.length}
                                    onClick={onPrimaryClick}
                                    className="btn-modal__first"
                                    styleType="primary"
                                >
                                    {primaryButton}
                                </ModalButton>
                            )}
                            {secondaryButton &&
                                (onSecondaryClick === 'callToBank' ? (
                                    <ModalButton
                                        as="a"
                                        href="https://www.mtsbank.ru/o-banke/kontakti/"
                                        target="_blank"
                                        rel="nofollow norefferrer noopener"
                                        styleType="hollow"
                                        className="btn-modal__second"
                                    >
                                        {secondaryButton}
                                    </ModalButton>
                                ) : (
                                    <ModalButton onClick={onSecondaryClick} styleType="hollow" className="btn-modal__second">
                                        {secondaryButton}
                                    </ModalButton>
                                ))}
                        </ModalButtonGroup>
                    </ModalContent>
                </ModalContentWrapper>
            </aside>
        </FocusTrap>
    );
    return ReactDOM.createPortal(modal, document.body);
};

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    background: rgba(50, 60, 71, 0.9);
    transition: all 0.3s ease-out;
`;

const ModalContentWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media (min-width: ${breakpoints.tablet}) {
        justify-content: center;
    }
`;

const ModalContent = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    width: calc(100% - 2.5rem);
    padding: 1.25rem 1rem;
    background-color: ${palette.white};
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.12), 0 1.25rem 1.875rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    transition: all 0.3s ease-out;

    h2 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.375rem;
        padding-bottom: 0.125rem;
        margin: 0;
    }

    & > svg {
        align-self: center;
        margin-bottom: 1.25rem;
    }

    @media (min-width: ${breakpoints.tablet}) {
        width: 34rem;
        padding: 2rem 0 0;

        h2 {
            font-size: 1.5rem;
            line-height: 1.875rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
`;

const ModalTextContainer = styled.div`
    margin: 0.5rem 0 0;

    @media (min-width: ${breakpoints.mobileL}) {
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 0.75rem auto 0;
    }
`;

const ModalText = styled(Text)`
    margin: 0;
    white-space: pre-wrap;
`;

const ModalButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    @media (min-width: 560px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    @media (min-width: ${breakpoints.tablet}) {
        padding: 1rem;
        background-color: ${palette.extraLightGray};
        border-radius: 0 0 0.25rem 0.25rem;
    }
`;

const ModalButton = styled(Button)<{ isSingle?: boolean }>`
    text-decoration: none;

    &.btn-modal__first {
        padding: 0.625rem 0.75rem;
        margin-top: 1.25rem;
    }

    &.btn-modal__second {
        margin: 0.5rem auto auto;
        padding: 0.625rem 0;
    }

    @media (min-width: 560px) {
        &.btn-modal__first,
        &.btn-modal__second {
            width: auto;
            margin: 0;
        }

        &.btn-modal__first {
            padding: 0.625rem 1.25rem;
            ${({ isSingle }) => !isSingle && `margin-left: 2rem;`};
        }

        &.btn-modal__second {
            order: -1;
        }
    }
`;
