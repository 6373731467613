import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Content } from 'components/common/Content';
import { Form } from 'components/common/Form';
import { PolicyText } from 'components/common/PolicyText';
import { FieldWithMask } from 'components/fields/FieldWithMask';
import { StateContext } from 'state';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { getNumericValue } from 'utils/getNumericValue';
import { stepParams } from 'lib/stepParams';
import { isIosWebview, isWebview } from 'utils/isWebview';
import { phoneInputEventGTM, furtherClickGTM, showScreenLoginPageGTM } from 'utils/GTM';

interface FormValues {
    phone: string;
}

// eslint-disable-next-line no-useless-escape
const phoneRegex = /^(\+7|7|8)?[\s\-]?\(?[3489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

const query = new URLSearchParams(window.location.search);
const phoneFromQuery = query.get('phone');

export const Login = () => {
    const {
        login: { heading, buttonText, inputError },
    } = stepParams;

    const { login, setLogin, showOTP, setUsername } = useContext(StateContext);

    const [errorText, setErrorText] = useState<string>(inputError);

    const [errors, setErrors] = useState({
        phone: false,
    });

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        watch,
        formState: { isSubmitting },
    } = useForm<FormValues>({
        defaultValues: {
            phone: phoneFromQuery?.length === 11 ? phoneFromQuery : '+7 ',
        },
    });

    useEffect(() => {
        register({ name: 'phone' });
    }, [register]);

    useEffect(() => {
        if (login && login.length) {
            setValue('phone', login);
        }

        return () => {
            reset();
        };
    }, [login, setValue, reset]);

    const clearErrors = useCallback(() => {
        setErrors({ phone: false });
    }, []);

    const onSubmit = useCallback(
        async (data: FormValues, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            let phone = getNumericValue(data.phone);

            phone = phone.length === 10 && !phone.startsWith('7') ? `7${phone}` : phone;
            if (phone.length < 11) {
                setErrorText(inputError);
                setErrors({ phone: true });
                phoneInputEventGTM({ success: false, login });
                return;
            }

            if (!phoneRegex.test(phone)) {
                setErrorText('Неправильный номер телефона');
                setErrors({ phone: true });
                phoneInputEventGTM({ success: false, login });
                return;
            }
            phoneInputEventGTM({ success: true, login });
            furtherClickGTM({ login });

            setLogin(phone);
            setUsername('');
            await showOTP(phone);
        },
        [login, setLogin, setUsername, showOTP, inputError]
    );

    const onChange = useCallback(
        (e) => {
            e.preventDefault();
            clearErrors();
            const { value } = e.target;
            let newValue;
            const onlyDigits = value.replace(/\D/g, '');
            if (onlyDigits.length === 10 && !value.startsWith('+7')) {
                return setValue('phone', `+7 ${onlyDigits}`);
            }
            if (value.startsWith('+79')) {
                newValue = value.replace('+79', '+7 9');
            } else if (value.startsWith('79')) {
                newValue = value.replace('79', '+7 9');
            } else if (/\+7.*\+7/.test(value)) {
                newValue = value.replace('+7', '');
            } else if (!value.startsWith('+7 ')) {
                newValue = '+7 ';
            } else {
                newValue = value;
            }

            return setValue('phone', newValue);
        },
        [clearErrors, setValue]
    );

    const onClearClick = useCallback(() => {
        setValue('phone', '+7 ');
        clearErrors();
    }, [clearErrors, setValue]);

    useEffect(() => {
        showScreenLoginPageGTM();
    }, []);

    return (
        <Content heading={heading}>
            <Form onSubmit={handleSubmit(onSubmit)} buttonText={buttonText} isError={errors.phone} isSubmitting={isSubmitting}>
                <FieldWithMask
                    errorMessage={errorText}
                    isError={errors.phone}
                    onChange={onChange}
                    onClick={onClearClick}
                    disabled={isSubmitting}
                    value={watch('phone')}
                    placeholder="+7"
                    name="phone"
                    id="phone"
                    // у пользователей на айос нормально не работает автокомплит, а иногда приложение крашится
                    // неизвестно в каком месте происходит карш, если после этого краши уйдут, то место найдено
                    // https://jira.mbrd.ru/browse/MP-17229
                    autoComplete={isIosWebview ? 'off' : 'tel'}
                    inputMode="tel"
                    type="tel"
                />
            </Form>
            {isWebview() && <PolicyText login={login} />}
        </Content>
    );
};
