import { isWebview } from 'utils/isWebview';
import md5 from 'blueimp-md5';
import { FormatAliasPhone, formatPhoneNumber } from 'utils/formatPhoneNumber';
import {
    ActionGroupGTM,
    ClickBackGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventContext,
    EventGTM,
    EventLabelsGTM,
    PageTypeGTM,
    ScreenNamesGTM,
    TouchPointGTM,
    UserAuthGTM,
} from './types';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Window {
        dataLayer: [{}];
    }
}

const emptyEvent = {
    touchPoint: isWebview() ? TouchPointGTM.WEBVIEW : TouchPointGTM.WEB,
    event: null,
    eventCategory: null,
    eventAction: null,
    eventLabel: null,
    eventValue: null,
    userId: null,
    userAuth: null,
    abonent: null,
    screenName: null,
    eventContext: null,
    buttonLocation: null,
    actionGroup: null,
    productName: null,
    numberType: null,
    accountType: null,
    pageType: null,
    ph: null,
    grclientId: null,
    projectName: null,
    eventContent: null,
    filterName: null,
    prCat: null,
    productId: null,
    intId: null,
    formId: null,
    bannerName: null,
    bannerId: null,
    regionId: null,
    region: null,
    clientId: null,
};

export const baseFurtherParams = {
    eventAction: EventActionGTM.BUTTON_CLICK,
    eventLabel: EventLabelsGTM.FURTHER,
    userAuth: UserAuthGTM.UNAUTHENTICATED,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.INTERACTIONS,
};

export const baseShowScreenParams = {
    event: EventGTM.MTS_PAGE_VIEW,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    eventAction: EventActionGTM.SCREEN_SHOW,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.NON_INTERACTIONS,
};

export function sendGmtEvent(event: { [K in keyof typeof emptyEvent]?: string | null }) {
    if (window.dataLayer) {
        window.dataLayer.push({ ...emptyEvent, ...event });
    }
}

export const getPh = (phoneNumber = '') => {
    const formattedPhoneNum = formatPhoneNumber(phoneNumber, FormatAliasPhone.DIGIT11) || '';

    return formattedPhoneNum ? md5(formattedPhoneNum) : null;
};

/** Событие срабатывает каждый раз при клике на кнопку "Назад"
 *  auth_form-button_click-nazad -vvod_pasporta | auth_form-button_click-nazad-vvod_karty
 *  auth_form-button_click-nazad-login | auth_form-form_add-kod_iz_sms-nazad
 */
export const clickBackGTM = ({ login, isPassport, isCards, isLogin, isNickName, isOtp }: ClickBackGTM) => {
    let screenName = null;
    let eventContext = null;

    if (isPassport) {
        screenName = ScreenNamesGTM.CONFIRMATION_PASSPORT;
    } else if (isCards) {
        screenName = ScreenNamesGTM.CONFIRMATION_CARD;
    } else if (isLogin || isOtp) {
        screenName = ScreenNamesGTM.LOGIN;
        eventContext = isOtp ? EventContext.KOD_FROM_SMS : null;
    } else if (isNickName) {
        screenName = ScreenNamesGTM.NAME_OR_NICKNAME;
    }

    sendGmtEvent({
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.BUTTON_CLICK,
        eventLabel: EventLabelsGTM.BACK,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName,
        eventContext,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
};
