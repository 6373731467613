const encode = (value: string | ArrayBuffer) => {
    const byteString = typeof value === 'string' ? new TextEncoder().encode(value) : new Uint8Array(value);

    return btoa(String.fromCodePoint(...byteString));
};

const decode = (base64: string) => {
    const binString = atob(base64);
    const utf8Array = Uint8Array.from<string>(binString, (c) => c.codePointAt(0) as number);

    return new TextDecoder().decode(utf8Array);
};

const urlEncode = (value: string | ArrayBuffer) => encode(value).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
export const base64 = { encode, decode, urlEncode };
