import React from 'react';
import styled from 'styled-components';
import { palette } from 'lib/theme';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';

interface Props {
    text: string;
}

export const Header = ({ text }: Props) => (
    <HeaderStyled>
        <Logo title="МТС" />
        <Heading>{text}</Heading>
    </HeaderStyled>
);

const HeaderStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    background-color: ${palette.white};
`;

const Heading = styled.h1`
    margin: 1.125rem 0 0;
    padding-bottom: 0.125rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
