import styled from 'styled-components';
import { breakpoints } from 'lib/theme';

export const Spacer = styled.div<{ height: string; regular?: boolean }>`
    height: ${({ regular, height }) => (regular ? height : '0')};

    @media (min-width: ${breakpoints.tablet}) {
        height: ${({ height }) => height};
    }
`;
