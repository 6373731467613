import { offerText } from './offer';
import { policyText } from './policy';

export const stepParams = {
    login: {
        heading: 'Введите номер телефона любого\u00A0оператора',
        buttonText: 'Далее',
        inputError: 'Номер телефона должен содержать 10\u00A0цифр, например,\u00A0+7\u00A0912\u00A0345-67-89',
    },
    otp: {
        heading: 'Введите код из SMS',
        subheading: (login: string) => `Мы отправили код\nна\u00A0${login}`,
    },
    confirmation: {
        heading: 'Подтвердите личность',
        subheading: 'Выберите способ',
    },
    identity: {
        heading: 'Подтверждение личности',
        buttonText: 'Далее',
        id: {
            subheading: 'Введите серию и\u00A0номер паспорта',
            errorSubheading: `Если не\u00A0помните\u00A0их, попробуйте подтвердить личность по\u00A0номеру карты МТС\u00A0Банка`,
            errorMessage: 'Неверные серия и номер паспорта',
            minLength: 2,
            maxLength: 100,
        },
        card: {
            subheading: 'Введите номер карты МТС Банка',
            errorSubheading: `Если не\u00A0помните, попробуйте подтвердить личность по\u00A0серии и\u00A0номеру\u00A0паспорта`,
            errorMessage: 'Неверный номер карты',
            minLength: 14,
            maxLength: 23,
        },
    },
    nickname: {
        heading: 'Добро пожаловать в\u00A0экосистему\u00A0МТС',
        buttonText: 'Далее',
        inputError: 'Используйте кириллицу (А—Я) без\u00A0знаков и\u00A0символов',
    },
    offer: {
        heading: `Соглашение о пользовании сервисом «Кошелек\u00A0МТС\u00A0Деньги»`,
        text: offerText,
    },
    policy: {
        heading: `Политика конфиденциальности сервиса «МТС\u00A0Деньги»`,
        buttonText: 'Принять',
        text: policyText,
    },
} as const;

export type StepParamsType = keyof typeof stepParams;
