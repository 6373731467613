export const palette = {
    white: '#FFFFFF',
    black: '#000000',
    green: '#04AA42',
    orange: '#F95721',
    mtsRed: '#E30611',
    midRed: '#C8050F',
    darkRed: '#AD050D',
    blue: '#0077DB',
    deepBlue: '#001424',
    gray: '#6E7782',
    midGray: '#9198A0',
    lightMidGray: '#B8BDC1',
    lightGray: '#E2E5EB',
    extraLightGray: '#F2F3F7',
} as const;

export const breakpoints = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '737px',
    desktop: '1024px',
} as const;

export const inputStyles = {
    phone: 'padding: 0.875rem 1.5rem 0.875rem 1rem;',
    otp: `
    padding: 0.875rem 1.5rem;
    letter-spacing: 0.2em;
  `,
    'identity-id': 'padding: 0.875rem 1.5rem;',
    'identity-card': 'padding: 0.875rem 1.5rem;',
    nickname: 'padding: 0.875rem 3.75rem 0.875rem 1.5rem;',
} as const;

export const buttonStyles = {
    primary: `
    cursor: pointer;
    color: ${palette.white};
    background-color: ${palette.mtsRed};
    &:hover {
      background-color: ${palette.midRed};
    }
    &:active {
      background-color: ${palette.darkRed};
    }
  `,
    secondary: `
    cursor: pointer;
    color: ${palette.black};
    background-color: ${palette.lightGray};
  `,
    disabled: `
    cursor: default;
    color: ${palette.midGray};
    background-color: ${palette.extraLightGray};
    &:hover {
      background-color: ${palette.extraLightGray};
    }
    &:active {
      background-color: ${palette.extraLightGray};
    }
  `,
    hollow: `
    cursor: pointer;
    color: ${palette.deepBlue};
    background-color: transparent !important;
  `,
    outline: `
    cursor: pointer;
    color: ${palette.deepBlue};
    background-color: ${palette.white} !important;
    border: 0.125rem solid ${palette.lightGray};
  `,
} as const;
