import styled from 'styled-components';
import { palette } from 'lib/theme';

export const LinkButton = styled.button<{ resend?: boolean; topSpace?: string }>`
    display: flex;
    align-items: flex-start;
    border: none;
    padding: ${({ resend }) => (resend ? '0 0 0.125rem' : '0.125rem 0 0.25rem')};
    margin-top: ${({ topSpace }) => topSpace || '2rem'};
    cursor: pointer;
    color: ${palette.blue};
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;

    &.using-tab:focus {
        color: ${palette.white};
        font-weight: 500;
        background-color: ${palette.mtsRed};
    }
`;
