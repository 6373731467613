const mapRequestData = {
    id: (value: string) => ({
        passport: {
            series: value.slice(0, 4),
            number: value.slice(4),
        },
    }),
    card: (value: string) => ({
        card: { value },
    }),
};

export const getIdentityRequestData = (type: keyof typeof mapRequestData, value: string) => mapRequestData[type](value);
