export const otpErrors = {
    error_verify_sms_code: {
        type: 'wrong',
        message: 'Неверный код.',
    },
    sms_code_expired: {
        type: 'expired',
        message: 'Истекло время действия кода. Запросите повторно.',
    },
    limit_verify_sms_code: {
        type: 'limit',
    },
    user_is_blocked: {
        type: 'blocked',
    },
    not_login: {
        type: 'login',
    },
    access_denied: {
        type: 'af',
    },
} as const;

type OtpError = keyof typeof otpErrors;

export const isOtpError = (error: any): error is OtpError => error in otpErrors;
