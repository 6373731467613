import React from 'react';
import { Heading, Text, theme, Image } from '@mtsbank/ui-kit';
import styled from 'styled-components';
import logoUpdateIosApp from 'assets/logoUpdateIosApp.png';

export const updateFormConfig = {
    header: 'Приложение устарело',
    text: 'Скачайте новое, чтобы продолжить пользоваться мобильным банком. Оно\u00A0называется "Мой\u00A0Кошелек"',
    buttonText: 'Скачать',
    href: 'https://redirect.appmetrica.yandex.com/serve/244529553660262614?utm_source=upd_ios62_all_sso',
} as const;

export const UpdateForm = () => {
    const { header, text, buttonText, href } = updateFormConfig;
    return (
        <Wrapper>
            <div>
                <Heading h={2}>{header}</Heading>
                <Text>{text}</Text>
            </div>
            <WrapperLogo>
                <Image style={{ backgroundColor: 'transparent' }} src={logoUpdateIosApp} alt="logoUpdateIosApp" />
            </WrapperLogo>
            <div>
                <ButtonWrapper>
                    <CustomButton
                        styleType="primary"
                        onClick={() => {
                            window.location.assign(href);
                        }}
                    >
                        {buttonText}
                    </CustomButton>
                </ButtonWrapper>
            </div>
        </Wrapper>
    );
};

const WrapperLogo = styled.div`
    margin-bottom: 10%;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: ${theme.opacities.op24}px ${theme.opacities.op24}px;
    max-width: ${theme.breakpoints.mobLg}px;
    height: 100%;
    position: relative;
`;

// todo удалить все стили ниже, и обновить кит на 13 версию
export const palette = {
    white: '#FFFFFF',
    black: '#000000',
    green: '#04AA42',
    orange: '#F95721',
    red: '#8080FF',
    midRed: '#7474fe',
    darkRed: '#5c5cfb',
    blue: '#0077DB',
    deepBlue: '#001424',
    gray: '#6E7782',
    midGray: '#9198A0',
    lightMidGray: '#B8BDC1',
    lightGray: '#E2E5EB',
    extraLightGray: '#F2F3F7',
} as const;

export const buttonStyles = {
    primary: `
    cursor: pointer;
    color: ${palette.white};
    background-color: ${palette.red};
    &:hover {
      background-color: ${palette.midRed};
    }
    &:active {
      background-color: ${palette.darkRed};
    }
  `,
    disabled: `
    cursor: default;
    color: ${palette.midGray};
    background-color: ${palette.extraLightGray};
    &:hover {
      background-color: ${palette.extraLightGray};
    }
    &:active {
      background-color: ${palette.extraLightGray};
    }
  `,
    hollow: `
    cursor: pointer;
    color: ${palette.deepBlue};
    background-color: transparent !important;
  `,
    outline: `
    cursor: pointer;
    color: ${palette.deepBlue};
    background-color: ${palette.white} !important;
    border: 0.125rem solid ${palette.lightGray};
  `,
} as const;

const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 46px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 0 ${theme.opacities.op12}px;
`;
export const CustomButton = styled.button<{ styleType: keyof typeof buttonStyles }>`
    width: 100%;
    max-width: ${theme.breakpoints.mobLg}px;
    padding: 0.875rem 0.75rem;
    margin-top: 1.25rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    border: none;
    font-feature-settings: 'tnum' on, 'lnum' on;
    outline: none;
    display: flex;
    justify-content: center;
    transition: background-color 0.1s ease;
    ${({ styleType }) => buttonStyles[styleType]}

    &:focus {
        outline: none !important;
        ${'' /* box-shadow: 0 0 0 3px ${palette.lightMidGray}; */}
    }

    -webkit-tap-highlight-color: transparent;
`;
