export const policyText = `Политика конфиденциальности (далее — Политика) сервиса «МТС Деньги» (далее — Сервис) действует в отношении той информации, которую ПАО «МТС» и ПАО
«МТС Банк» (далее – Организации) могут получить с устройства пользователя во время использования им Сервиса.

Использование Сервиса означает согласие пользователя с Политикой и указанными в ней условиями обработки информации, получаемой с устройства пользователя. Если пользователь с ней не согласен, ему нужно воздержаться от использования Сервиса.

Сервис и услуги в рамках Сервиса реализуются пользователю на основании договоров и соглашений с Организациями, которые в числе прочего регулируют все вопросы обработки и хранения Организациями персональных данных пользователя.

Политика применима только к Сервису. Организации не контролируют и не несут ответственность за информацию (последствия её передачи), отправленную пользователем третьей стороне, если такая передача произошла на ресурсе третьей стороны, куда
пользователь мог перейти по ссылкам из Сервиса. При использовании приложений с передачей текстовых и медиа-данных (в т.ч. чат с поддержкой) Сервиса Организации не
контролируют содержание контента клиента, который он размещает, передаёт и использует в Сервисе. Организации не инициируют передачу такого контента и/или иной
информации, размещённой Пользователем в Сервисе, и не несёт за них ответственность.

Организации имеют право вносить изменения в новых редакциях Политики и размещать их на сайте Организаций и/или в Сервисе. Обязанность самостоятельного ознакомления с актуальной редакцией Политики лежит на пользователе.

1. Состав информации, которая может быть получена с устройства пользователя при использовании Сервиса и цели её получения (далее – Пользовательские данные):

1.1. Информация о номерах телефонов из адресной книги устройства (если соответствующий вид устройства поддерживает данный функционал).

Цель: номера телефонов из адресной книги контактов на устройстве пользователя используются в Сервисе для упрощения процедуры денежных переводов.

При установке Сервиса система дополнительно информирует пользователя о целях использования данных о номерах телефонов из адресной книги устройства пользователя.

1.2. Информация о местоположении устройства пользователя (на основе данных сети оператора сотовой связи, сигналов GPS и иных данных, получаемых посредствам сети Интернет).

Цель: информирование клиента при использовании Сервиса о местоположении подразделений Организаций и устройств самообслуживания, а также о дополнительных
приложениях, доступных пользователю в зависимости от местоположения.

1.3. Фото- и видеоизображения (в т.ч. в потоковом режиме), полученные с использованием камеры устройства (если соответствующий вид устройства поддерживает данный функционал).

Цель: получение и использование фотоизображений в рамках услуг, реализуемых в Сервисе. В том числе для создания и сохранения фото и видео в профиле пользователя в Сервисе, получения фото платёжных документов, QR-кодов и штрих-кодов. Медиа- данные могут понадобиться для денежных переводов, оплаты услуг в Сервисе.

1.4. Информация о версии операционной системы и модели устройства.

Цель: анализ возможных ошибок в работе Сервиса и совершенствование работы Сервиса.

С целью анализа Организации могут передавать информацию об операционной системе и модели устройства сторонним организациям в обезличенном виде.

1.5. Информация об IP-адресе и адресе точки подключения пользователя.

Цель: повышение безопасности финансовых операций при использовании Сервиса.

1.6. Аудиоданные, полученные с использованием микрофона устройства (реализуется в Сервисе при наличии технической возможности)

Цель: выполнение аудиозвонков клиента в Организации с использованием Сервиса, использовании при поиске услуг и для голосового управления функционалом Сервиса.

1.7. Использование встроенного в Сервисе скрипта безопасности.

1.7.1. Цель: повышение уровня оперативной защиты:

• информация об установленном ПО, в том числе уникальный идентификатор установки ПО на устройстве пользователя, идентификатор и версия используемого ПО, уникальный идентификатор устройства;

1.7.2. Цель: выявление новых и сложных для обнаружения угроз информационной безопасности и их источников, угроз вторжения, а также повышения уровня защиты информации, хранимой и обрабатываемой пользователем на устройстве:

• информация об установленном ПО, в том числе уникальный идентификатор установки ПО на устройстве, идентификатор и версия используемого ПО, уникальный идентификатор устройства, данные о типе устройства и установленной на нем ОС и пакетов обновлений ОС;
• вид, название и использование устройства пользователя, установленные приложения и виджеты (далее — «Установленные приложения»), включая название, идентификатор,
систему, дату установки. Мы не собираем данные о самих Установленных приложениях, а только агрегированные данные, касающиеся факта использования.
• производительность аккумулятора и сети (например, состояние аккумулятора и использование зарядного устройства) вашего устройства, а также имена файлов, типы и размеры файлов (но не их содержимое) на вашем устройстве, включая количество свободного и используемого пространства в памяти устройства. Это необходимо для борьбы с вредоносным ПО и защиты пользователя.

1.7.3. Цель: улучшение качества работы ПО и обновления ПО:

• информация о результатах обновления ПО, в том числе тип и уникальный идентификатор устройства, на котором установлено ПО, уникальный идентификатор установки ПО на устройстве, идентификаторы ПО и задачи обновления, идентификатор и версия обновления ПО, идентификатор настроек обновления ПО, результат обновления ПО, идентификатор условия формирования передаваемой статистики, идентификатор настроек ПО,
идентификатор и название партнера, для которого выпущено ПО, язык локализации ПО, уникальный идентификатор и тип установленной лицензии, данные об установленной ОС доступной для ПО;
• информация о возникших ошибках в работе компонент ПО, в том числе тип и время возникновения ошибки, а также идентификатор компонента ПО и задачи, при выполнении которой возникла ошибка, копия участка оперативной памяти устройства используемого приложения, относящегося к вызвавшему ошибку компоненту ПО, тип и время создания копии участка оперативной памяти, даты создания, активации и истечения используемого лицензионного ключа, количество компьютеров, на которое рассчитана лицензия, имя первичного файла обновления, дата и время первичных файлов предыдущего и нового обновлений, дата и время завершения последнего обновления, версия набора передаваемой информации, время отправки информации.

1.7.4. Цель: защита пользователя от мошенничества при посещении страниц сайта Организаций:

• Идентификатор и версию используемого ПО, уникальный идентификатор компьютера, информацию о состоянии используемого браузера, идентификатор параметров настроек ПО и
другие технические данные.

1.7.5. Цель: повышение уровня защиты информации, хранимой и обрабатываемой пользователем на устройстве:

• Идентификатор и версия используемого ПО, уникальный идентификатор устройства, тип и версия установленной ОС, тип и версия браузера, время последнего обновления
антивирусных баз;
• информация об обнаруженных угрозах и уязвимостях, в том числе название угрозы и результат её устранения, признак обнаружения уязвимости ОС, идентификатор угрозы, обнаруженный при запуске браузера, признак обнаружения загрузки недоверенного модуля;
• информация об уровне защиты от получения снимков экрана

Указанные выше данные по встроенному скрипту могут также использоваться для формирования отчетов по рискам информационной безопасности.

Дополнительно в рамках настоящей Политики Организации вправе использовать дополнительные программные инструменты (в том числе партнеров Организаций) и cookies для сбора и обработки статистической информации об использовании пользователем Сервиса и услуг в рамках Сервиса для целей улучшения Сервиса;
 
2. Условия обработки Информации пользователя

2.1. В соответствии с Политикой Организации осуществляет обработку только той информации и только для тех целей, которые определены в пункте 1.

2.2. Встроенный в Сервис скрипт обрабатывает полученную информацию, исключая данные, относящие к определенному физическому лицу, и никак не связывает обрабатываемую информацию с ФИО пользователя.

2.3. Организации принимают все зависящие от Организаций меры для защиты Информации пользователя от неправомерного доступа третьих лиц, использования, копирования и распространения.

2.4. Для целей, изложенных в настоящей Политике, Организации могут привлекать к обработке Информации пользователя партнёров, с которыми у Организаций заключены соответствующие соглашения о конфиденциальности. Передача партнёрам обезличенных данных об использовании Сервиса для целей улучшения работы Сервиса осуществляется на основании договоров с партнерами.

2.5. Информация пользователя может сохраняться на ресурсах Организаций и их партнёров в течение срока действия договорных отношений между Организациями и пользователем, касаемо Сервиса, а также в течение 5 лет после расторжения таких договоров.

2.6. Информация Пользователя может быть предоставлена государственным органам в соответствии с требованиями действующего законодательства.
`;
