import semver from 'semver';
import { getAdditionalQueryParams } from './getAdditionalQueryParams';

export function getAppVersion(): string | false {
    const { appVersion } = getAdditionalQueryParams();

    if (!appVersion) {
        return false;
    }

    if (Array.isArray(appVersion)) {
        return String(appVersion);
    }

    return appVersion;
}

const version = getAppVersion();

// для ссылки href="tel:......"
export function canLinkIntoPhone() {
    if (!version) {
        return false;
    }
    return semver.gtr(version, '1.21.x');
}

// версия для ccылки на условия доступа
export function canShowLink() {
    if (!version) {
        return false;
    }
    return semver.gtr(version, '1.23.x');
}

// новую ссылку на политику показываем с 1.67 версии приложения
export function isNewPolicyLink() {
    if (!version) {
        return false;
    }
    return semver.gtr(version, '1.66.x');
}
