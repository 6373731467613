import React, { FC, ReactNode, useMemo, useState } from 'react';
import { WebauthnContext } from './context';

export const WebauthnProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [hasBackground, setBackground] = useState(false);

    const contextValue = useMemo(
        () => ({
            hasBackground,
            setBackground,
        }),
        [hasBackground]
    );

    return <WebauthnContext.Provider value={contextValue}>{children}</WebauthnContext.Provider>;
};
