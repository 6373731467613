import { getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventGTM,
    EventLabelsGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';

const baseClickParamsBio = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    eventAction: EventActionGTM.BUTTON_CLICK,
    pageType: PageTypeGTM.SSO_AUTH,
};

/** На экране подключения биометрии
 *  Событие срабатывает при клике на кнопку "Разрешить"
 *  auth_form-button_click-vkluchit */
export const clickEnableUseBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseClickParamsBio,
        eventLabel: EventLabelsGTM.RESOLVE,
        userAuth: UserAuthGTM.AUTHENTICATED,
        screenName: ScreenNamesGTM.BIOMETRIYA,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
};

/** На экране подключения биометрии
 *  Событие срабатывает при клике на кнопку "Пропустить"
 *  auth_form-button_click-propustit */
export const clickCancelUseClickBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseClickParamsBio,
        eventLabel: EventLabelsGTM.SKIP,
        userAuth: UserAuthGTM.AUTHENTICATED,
        screenName: ScreenNamesGTM.BIOMETRIYA,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
};

/** В toast при успешном подключении биометрии
 *  Событие срабатывает каждый раз при клике на кнопку "В личный кабинет"
 *  auth_form-click-v_lichnyi_kabinet */
export const clickToGoPersonalAccBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseClickParamsBio,
        eventLabel: EventLabelsGTM.IN_PERSONAL_ACC,
        userAuth: UserAuthGTM.AUTHENTICATED,
        screenName: ScreenNamesGTM.BIOMETRIYA,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
};

/** На экране выбора способа входа "По биометрии" или "По номеру телефона"
 *  Событие срабатывает каждый раз при клике на кнопку "По биометрии"
 *  auth_form-click-po_biometrii */
export const clickLoginWithBioGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseClickParamsBio,
        eventLabel: EventLabelsGTM.BY_BIOMETRY,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.AUTH,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });
};

/** На экране выбора способа входа "По биометрии" или "По номеру телефона"
 *  Событие срабатывает каждый раз при клике на кнопку "По номеру телефона"
 *  auth_form-click-po_nomeru_telefona */
export const clickLoginWithPhoneGTM = ({ login }: ParamsGTM) => {
    sendGmtEvent({
        ...baseClickParamsBio,
        eventLabel: EventLabelsGTM.BY_PHONE_NUMBER,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.AUTH,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });
};
