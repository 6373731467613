import React from 'react';
import styled from 'styled-components';
import { palette } from 'lib/theme';
import { ReactComponent as IconId } from 'assets/svg/iconMenuID.svg';
import { ReactComponent as IconCard } from 'assets/svg/iconMenuCard.svg';
import { ReactComponent as Checkbox } from 'assets/svg/iconCheckbox.svg';

export enum CardType {
    'menuId' = 'menuId',
    'menuCard' = 'menuCard',
}

const cards = {
    [CardType.menuId]: {
        icon: IconId,
        label: 'Серия и номер паспорта',
    },
    [CardType.menuCard]: {
        icon: IconCard,
        label: 'Номер карты МТС Банка',
    },
};

interface Props {
    onClick: (e: React.MouseEvent) => void;
    type: CardType;
    first?: boolean;
}

export const Card = ({ type, first, onClick }: Props) => {
    const Icon = cards[type].icon;
    const { label } = cards[type];

    return (
        <CardContainer first={first} onClick={onClick}>
            <Icon />
            <CardLabel>{label}</CardLabel>
            <Checkbox className="checkbox" />
        </CardContainer>
    );
};

const CardContainer = styled.button<{ first?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.875rem;
    margin-top: ${({ first }) => (first ? '0' : '.75rem')};
    position: relative;
    cursor: pointer;
    background-color: ${palette.white};
    border-radius: 0.25rem;
    border: 2px solid ${palette.lightGray};

    .checkbox {
        display: none;
        position: absolute;
        right: -0.125rem;
        top: -0.125rem;
    }

    &:hover,
    &:focus {
        outline: none;
        background-color: ${palette.white};
        border-color: ${palette.mtsRed};

        .checkbox {
            display: block;
        }
    }
`;

const CardLabel = styled.span`
    margin: 0.75rem 0 0;
    padding: 0.125rem 0 0.25rem;
    text-align: left;
`;
