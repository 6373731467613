import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { UseFormMethods } from 'react-hook-form';
import { Input } from 'components/common/Input';
import { palette } from 'lib/theme';
import { setCaretPosition } from 'utils/setCaretPosition';

interface Props {
    inputValue: string;
    isError: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    register: UseFormMethods['register'];
    disabled?: boolean;
}

export const OtpField = ({ register, disabled, inputValue, isError, onChange }: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [activeDigit, setActiveDigit] = useState(0);
    const [otpValues, setOtpValues] = useState(['', '', '', '']);
    const [focus, setFocus] = useState(true);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setActiveDigit(inputValue.length);
        setOtpValues((prevState) => prevState.map((item, index) => inputValue[index] || ''));

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputValue]);

    const onFocus = useCallback(() => {
        if (inputRef.current) {
            setFocus(true);
            setCaretPosition(inputRef.current);
        }
    }, []);

    const onBlur = useCallback(() => {
        setFocus(false);
    }, []);

    // prevent caret to move back by pressing left arrow button
    const onKeyUp = useCallback((e: React.KeyboardEvent) => {
        e.preventDefault();
        if (inputRef.current && e.keyCode === 37) {
            setCaretPosition(inputRef.current);
        }
    }, []);

    // set caret to the end if caret was set accidently in random place by mouse click
    const onMouseUp = useCallback((e: React.MouseEvent) => {
        e.preventDefault();

        if (inputRef.current) {
            setCaretPosition(inputRef.current);
        }
    }, []);

    return (
        <OtpFieldStyled>
            <HiddenInput
                id="otp"
                name="otp"
                type="tel"
                autoComplete="off"
                maxLength={4}
                inputMode="numeric"
                pattern="[0-9]*"
                ref={(e) => {
                    register(e, { required: true, minLength: 4 });
                    inputRef.current = e;
                }}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onMouseUp={onMouseUp}
                onFocus={onFocus}
                onBlur={onBlur}
                isError={isError}
                disabled={disabled}
            />
            <DigitsBlock>
                {otpValues.map((value, index) => (
                    <SingleDigit
                        key={`digit-${index + 1}`}
                        isFocused={activeDigit === index && focus}
                        isError={isError}
                        disabled={disabled}
                        data-testid="digit"
                    >
                        {value}
                    </SingleDigit>
                ))}
            </DigitsBlock>
        </OtpFieldStyled>
    );
};

const cursorBlinking = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const OtpFieldStyled = styled.div`
    position: relative;
    width: 10.25rem;
    min-height: 2.25rem;
    padding-bottom: 1rem;
`;

const HiddenInput = styled(Input)`
    opacity: 0;
    position: relative;
    padding: 0;
    height: 2.25rem;
    z-index: 20;
    width: 100%;
    font-size: 1px;
    background-color: transparent;
    outline: none;
    -webkit-transform: translate3d(0, 0, 0) !important;
`;

const DigitsBlock = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    border: none;
    position: absolute;
    z-index: 1;
    top: 0;
`;

const SingleDigit = styled.div<{
    disabled?: boolean;
    isError?: boolean;
    isFocused?: boolean;
}>`
    width: 2rem;
    height: 2.25rem;
    border-bottom: 2px solid ${palette.lightGray};
    margin: 0;
    text-align: center;
    position: relative;
    border-bottom-color: ${({ isFocused, isError }) => {
        if (isError) {
            return palette.orange;
        }

        if (isFocused) {
            return palette.blue;
        }

        return palette.lightGray;
    }};

    &::before {
        ${({ isFocused, disabled }) =>
            isFocused &&
            !disabled &&
            css`
                content: '';
                width: 1px;
                height: 1.125rem;
                background-color: ${palette.deepBlue};
                display: block;
                position: absolute;
                top: 0.5rem;
                left: 50%;
                animation: ${cursorBlinking} 1s infinite steps(1);
            `}
    }
`;
