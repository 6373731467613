import { baseFurtherParams, baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventGTM,
    EventLabelsGTM,
    PageTypeGTM,
    ParamsGTM,
    ParamsPhoneInputGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';

const baseLoginPageParams = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    screenName: ScreenNamesGTM.LOGIN,
};

/** Переход на экран "Ввод номера телефона"
 * Событие срабатывает каждый раз при переходе на страницу
 * scrn
 * */
export const showScreenLoginPageGTM = () =>
    sendGmtEvent({
        ...baseShowScreenParams,
        screenName: ScreenNamesGTM.LOGIN,
        eventLabel: EventLabelsGTM.ENTRY_BY_NUMBER,
    });

/** Ввод номера телефона
 *
 *  auth_form-form_add-nomer_telefona || auth_form-error_show-nomer_vveden_nekorrektno */
export const phoneInputEventGTM = ({ login, success }: ParamsPhoneInputGTM) =>
    sendGmtEvent({
        ...baseLoginPageParams,
        eventAction: success ? EventActionGTM.FORM_ADD : EventActionGTM.ERROR_SHOW,
        eventLabel: success ? EventLabelsGTM.PHONE_NUMBER : EventLabelsGTM.NUMBER_ENTERED_INCORRECTLY,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: success ? ActionGroupGTM.INTERACTIONS : ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });

/** Клик на кнопку "Далее" на экране ввода номера телефона
 * auth_form-button_click-dalee */
export const furtherClickGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseLoginPageParams,
        ...baseFurtherParams,
        ph: getPh(login),
    });
