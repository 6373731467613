import React from 'react';
import styled, { keyframes } from 'styled-components';
import { palette } from 'lib/theme';

const spinnerSize = '2.75rem';

interface Props {
    isBlock?: boolean;
}

export const Spinner = ({ isBlock }: Props) => (
    <SpinnerBackground isBlock={isBlock}>
        <SpinnerStyled className="spinner" viewBox="0 0 22 22">
            <circle className="basepath" cx="11" cy="11" r="10" />
            <circle className="spinpath" cx="11" cy="11" r="10" />
        </SpinnerStyled>
    </SpinnerBackground>
);

const rotate = keyframes`
  100% {
    transform: rotateZ(360deg);
  }
`;

const spin = keyframes`
  0%, 
  25% {
    stroke-dashoffset: 61;
    transform: rotate(-100deg);
  }
  50%,
  75% {
    stroke-dashoffset: 12;
    transform: rotate(0deg);
  }
  100% {
    stroke-dashoffset: 61;
    transform: rotate(260deg);
  }
`;

const SpinnerBackground = styled.div<{ isBlock?: boolean }>`
    display: ${({ isBlock }) => (isBlock ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${palette.white};
    z-index: 200;
    opacity: 0.9;
`;

const SpinnerStyled = styled.svg`
    animation: ${rotate} 2s linear infinite;
    z-index: 101;
    opacity: 1;
    width: ${spinnerSize};
    height: ${spinnerSize};
    color: ${palette.lightGray};

    .basepath,
    .spinpath {
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
    }

    .basepath {
        stroke: currentColor;
    }

    .spinpath {
        stroke: ${palette.mtsRed};
        transform-origin: 50% 50%;
        stroke-dasharray: 62;
        animation: ${spin} 1.5s ease-in-out infinite both;
    }
`;
