import React from 'react';
import styled from 'styled-components';
import { palette } from 'lib/theme';

interface Props {
    counter: number;
}

export const ResendText = ({ counter }: Props) => <TextStyled>Отправить код через {counter} сек.</TextStyled>;

const TextStyled = styled.p`
    width: 100%;
    max-width: 100%;
    margin: 1.25rem 0 0;
    line-height: 1.125rem;
    padding-bottom: 0.125rem;
    color: ${palette.gray};
`;
