import { Confirmation } from 'components/steps/Confirmation/Confirmation';
import { Identity } from 'components/steps/Identity/Identity';
import { Login } from 'components/steps/Login/Login';
import { Nickname } from 'components/steps/Nickname/Nickname';
import { Otp } from 'components/steps/Otp/Otp';
import { Restricted } from 'components/steps/Restricted/Restricted';

const steps = {
    login: Login,
    otp: Otp,
    identityID: Identity,
    identityCard: Identity,
    nickname: Nickname,
    confirmation: Confirmation,
    restricted: Restricted,
};

export type StepType = keyof typeof steps;

export const isStepType = (step: any): step is StepType => step in steps;

export const getStep = (type: StepType) => steps[type];
