import { baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventContentGTM,
    EventGTM,
    EventLabelsGTM,
    IdentificationCLickPropsGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';

const baseConfirmationParams = {
    userAuth: UserAuthGTM.UNAUTHENTICATED,
    screenName: ScreenNamesGTM.CONFIRM,
};

/** Показ экрана "Выбор способа подтверждения"
 * scrn
 * */
export const showScreenConfirmationGTM = () =>
    sendGmtEvent({
        ...baseShowScreenParams,
        ...baseConfirmationParams,
        eventLabel: EventLabelsGTM.CONFIRMATION_SCREEN,
    });

/** Клики по блокам на экране Подтверждение личности
 * Событие срабатывает каждый раз при клике на кнопку "Серия и номер паспорта" или "номер карты"
 * auth_form-button_click-seriya_nomer_pasporta
 * auth_form-button_click-nomer_karty
 * */
export const identificationCLickGTM = ({ isPassport, login }: IdentificationCLickPropsGTM) =>
    sendGmtEvent({
        ...baseConfirmationParams,
        eventLabel: isPassport ? EventLabelsGTM.PASSPORT : EventLabelsGTM.CARD_NUMBER,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.BUTTON_CLICK,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });

/** Клик по элементу Я не userName.... на экране Подтверждение личности
 * auth_form-button_click-ne_klient_banka-sposob_podtverzhdeniya */
export const iAmNotCLickGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseConfirmationParams,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventCategory: EventCategoryGTM.AUTH_FORM,
        eventAction: EventActionGTM.BUTTON_CLICK,
        eventLabel: EventLabelsGTM.NO,
        eventContent: EventContentGTM.CONFIRMATION_METHOD,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });
