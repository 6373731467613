import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useToast } from '@mtsbank/ui-kit';
import { WebauthnCheck } from 'utils/webauthn/WebauthnCheck';
import { WebauthnView } from 'types/webauthn';
import { WebauthnEnableForm } from 'components/webauthn/WebauthnEnableForm';
import { AuthorizationForm } from 'components/webauthn/AuthorizationForm';
import { WebauthnContext } from 'components/webauthn/context';

export const WebauthnPage = () => {
    const webauthnCheck = new WebauthnCheck();
    const [webauthnView] = useState(webauthnCheck.isWebauthnEnabled() ? WebauthnView.AUTHORIZATION : WebauthnView.ENABLE);
    const { toastStorage } = useToast();
    const { hasBackground } = useContext(WebauthnContext);

    const isAuthView = webauthnView === WebauthnView.AUTHORIZATION;
    const shouldRenderToastBackground = Object.keys(toastStorage).length > 0 || hasBackground;

    return (
        <>
            {isAuthView ? <AuthorizationForm /> : <WebauthnEnableForm webauthnCheck={webauthnCheck} />}
            {shouldRenderToastBackground && <ToastBackground />}
        </>
    );
};

const ToastBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 11;
`;
