import React, { ReactNode } from 'react';
import { Button } from 'components/common/Button';
import { Error } from 'components/common/Error';

interface Props {
    children: ReactNode;
    isSubmitting: boolean;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    buttonText?: string;
    error?: string;
    isButtonActive?: boolean;
    isError?: boolean;
}

export const Form = ({ onSubmit, buttonText, error, isError, isSubmitting, isButtonActive = true, children }: Props) => (
    <form onSubmit={onSubmit}>
        {children}
        {isError && <Error data-testid="form-error">{error}</Error>}
        {buttonText && (
            <Button type="submit" styleType={isButtonActive ? 'primary' : 'disabled'} disabled={isSubmitting || !isButtonActive}>
                {buttonText}
            </Button>
        )}
    </form>
);
