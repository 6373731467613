import 'core-js';
import './lib/gib';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'assets/globalStyles';
import { BASE_PATH } from 'constants/index';
import { App } from './components/App/App';

ReactDOM.render(
    <>
        <GlobalStyle />
        <BrowserRouter basename={BASE_PATH}>
            <App />
        </BrowserRouter>
    </>,
    document.getElementById('app')
);
