import { StepType } from 'components/steps/Steps';

export interface QuantityWebauthn {
    attemptNumber: number;
    date: number;
    expires: number;
}

export type SetQuantityWebauthn = (params: Partial<QuantityWebauthn> & Pick<QuantityWebauthn, 'attemptNumber'>) => void;

export interface WebauthnData {
    enabled: boolean;
    phone: string;
}

export interface PublicKeyCredentialCreate extends Omit<PublicKeyCredential, 'getClientExtensionResults' | 'response'> {
    response: AuthenticatorAttestationResponse;
}

export interface PublicKeyCredentialGet extends Omit<PublicKeyCredential, 'getClientExtensionResults' | 'response'> {
    response: AuthenticatorAssertionResponse;
}
export enum ToastType {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
}

export enum WebauthnView {
    ENABLE = 'enable',
    AUTHORIZATION = 'authorization',
}

export const isWebauthnData = (data: Partial<WebauthnData>): data is WebauthnData =>
    Boolean(data.phone?.match(/^7\d{10}$/)) && typeof data?.enabled === 'boolean';

export const isQuantityWebauthn = (data: Partial<QuantityWebauthn>): data is QuantityWebauthn =>
    typeof data?.date === 'number' && typeof data?.expires === 'number' && typeof data?.attemptNumber === 'number';

export interface LocationState {
    redirectUrl?: string;
    stepType?: StepType;
    webauthn?: boolean;
}

export interface WebauthnContextProps {
    hasBackground: boolean;
    setBackground: (value: boolean) => void;
}

export enum WebauthnError {
    CREDENTIALS_NOT_EXIST = 'credentials_not_exist',
    AUTHENTICATOR_CANCELED = 'authenticator_canceled',
}
