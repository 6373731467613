interface AdditionalParams {
    appVersion?: string;
    fromAccountList?: boolean;
    tid?: string;
}

type GetAdditionalQueryParams = () => AdditionalParams;
export const getAdditionalQueryParams: GetAdditionalQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    const additionalParams = query.get('additional_params');

    return additionalParams ? JSON.parse(window.atob(additionalParams)) : {};
};
