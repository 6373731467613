import React, { ReactNode } from 'react';
import { Header } from 'components/common/Header';
import { Text } from 'components/common/Text';

interface Props {
    heading: string;
    children?: ReactNode;
    subheading?: string;
}

export const Content = ({ heading, subheading, children }: Props) => (
    <>
        <Header text={heading} />
        {subheading && <Text>{subheading}</Text>}
        {children}
    </>
);
