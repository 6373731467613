import styled from 'styled-components';
import { breakpoints, buttonStyles } from 'lib/theme';

export const LinkButtonOutline = styled.a<{ styleType: keyof typeof buttonStyles }>`
    text-decoration: none;
    width: 100%;
    padding: 0.875rem 0.75rem;
    margin-bottom: 0.75rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    ${({ styleType }) => buttonStyles[styleType]}

    &:focus {
        outline: none !important;
        ${'' /* box-shadow: 0 0 0 3px ${palette.lightMidGray}; */}
    }

    -webkit-tap-highlight-color: transparent;

    &.btn-modal__first {
        padding: 0.625rem 0;
    }

    &.btn-modal__first,
    &.btn-modal__second {
        margin-top: 1.25rem;
    }

    @media (min-width: 560px) {
        &.btn-modal__first {
            padding: 0.625rem 1.25rem;
            margin-left: 1rem;
        }

        &.btn-modal__first,
        &.btn-modal__second {
            width: auto;
            margin: 0;
        }

        &.btn-modal__second {
            order: -1;
        }
    }
    @media (min-width: ${breakpoints.tablet}) {
        &.btn-modal__first {
            margin-left: 1.75rem;
        }
    }
`;
