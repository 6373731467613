import React from 'react';
import styled from 'styled-components';
import { palette, breakpoints } from 'lib/theme';
import { ReactComponent as Icon } from 'assets/svg/iconClear.svg';

interface Props {
    isShown: boolean;
    onClick: (e?: React.MouseEvent) => void;
    inModal?: boolean;
}

export const ClearButton = ({ onClick, isShown, inModal }: Props) => (
    <ButtonStyled data-testid="clear" onClick={onClick} isShown={isShown} inModal={inModal} tabIndex={0}>
        <Icon />
    </ButtonStyled>
);

const ButtonStyled = styled.div<{ inModal?: boolean; isShown?: boolean }>`
    opacity: ${({ isShown }) => (isShown ? '1' : '0')};
    display: ${({ inModal }) => (inModal ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    width: ${({ inModal }) => (inModal ? '1.5rem' : '3.25rem')};
    height: ${({ inModal }) => (inModal ? '1.5rem' : '3.25rem')};
    position: absolute;
    right: ${({ inModal }) => (inModal ? '.25rem' : '0')};
    top: ${({ inModal }) => (inModal ? '.25rem' : '0')};
    cursor: ${({ isShown }) => isShown && 'pointer'};
    background-color: transparent !important;
    color: ${palette.midGray};
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none !important;
    }

    &.using-tab:focus {
        outline: none !important;
        border-radius: 0.125rem;
        box-shadow: 0 0 0 3px ${palette.lightMidGray};
    }

    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
    }
`;
