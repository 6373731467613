import styled from 'styled-components';
import { buttonStyles } from 'lib/theme';

export const Button = styled.button<{ styleType: keyof typeof buttonStyles }>`
    width: 100%;
    padding: 0.875rem 0.75rem;
    margin-top: 1.25rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    border: none;
    font-feature-settings: 'tnum' on, 'lnum' on;
    outline: none;
    display: flex;
    justify-content: center;
    transition: background-color 0.1s ease;
    ${({ styleType }) => buttonStyles[styleType]}

    &:focus {
        outline: none !important;
        ${'' /* box-shadow: 0 0 0 3px ${palette.lightMidGray}; */}
    }

    -webkit-tap-highlight-color: transparent;
`;
